import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddonInputComponent } from './addon/addon-input/addon-input.component';
import { AddonListComponent } from './addon/addon-list/addon-list.component';
import { LoginComponent } from './auth/login/login.component';
import { CategoryInputComponent } from './category/category-input/category-input.component';
import { CategoryListComponent } from './category/category-list/category-list.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { IconsComponent } from './icons/icons.component';
import { MerchantOrdersComponent } from './merchant-orders/merchant-orders.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { OrderDetailsComponent } from './orders/order-details/order-details.component';
import { OrderListComponent } from './orders/order-list/order-list.component';
import { PendingOrderListPageComponent } from './orders/pending-order-list-page/pending-order-list-page.component';
import { ProductAddonsComponent } from './product/product-addons/product-addons.component';
import { ProductInputOtherComponent } from './product/product-input-other/product-input-other.component';
import { ProductInputTankComponent } from './product/product-input-tank/product-input-tank.component';
import { ProductInputComponent } from './product/product-input/product-input.component';
import { ProductTableComponent } from './product/product-table/product-table.component';
import { ProductAddonListComponent } from './product/product_addons_list/product_addons_list.component';
import { RecommendationListComponent } from './recommendation/recommendation-list/recommendation-list.component';
import { TypographyComponent } from './typography/typography.component';
import { UserDetailsComponent } from './user-details/user-details.component';
import { UserListComponent } from './user-list/user-list.component';
import { VendorDetailComponent } from './vendor/vendor-detail/vendor-detail.component';
import { VendorListComponent } from './vendor/vendor-list/vendor-list.component';
import { OfferListPageComponent } from './pages/offer-list-page/offer-list-page.component';
import { AuthGaurd } from './guards/auth.gaurd';

import { shortPath } from './app-routes.const';
const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },

  // static routes
  {
    path: 'dashboard',
    canActivate: [AuthGaurd],
    component: DashboardComponent,
  },
  { path: 'typography', component: TypographyComponent },
  { path: 'icons', component: IconsComponent },
  { path: 'notifications', component: NotificationsComponent },

  { path: 'vendors', component: VendorListComponent },
  // { path: 'vendor_input',        component: VendorDetailComponent    },
  { path: 'vendor_input/:id', component: VendorDetailComponent },

  {
    path: 'categories',
    canActivate: [AuthGaurd],
    component: CategoryListComponent,
  },
  {
    path: 'category_input/:id',
    canActivate: [AuthGaurd],
    component: CategoryInputComponent,
  },

  {
    path: 'products',
    canActivate: [AuthGaurd],
    component: ProductTableComponent,
  },

  {
    path: 'product_input/:id',
    canActivate: [AuthGaurd],
    component: ProductInputComponent,
  },
  {
    path: 'product_input/:id/:cid',
    canActivate: [AuthGaurd],
    component: ProductInputComponent,
  },

  {
    path: 'product_addons/:pid/:id',
    canActivate: [AuthGaurd],
    component: ProductAddonsComponent,
  },
  {
    path: 'product_addons_list/:id',
    canActivate: [AuthGaurd],
    component: ProductAddonListComponent,
  },
  {
    path: 'product_addons/:pid/:id/:msg',
    canActivate: [AuthGaurd],
    component: ProductAddonsComponent,
  },

  {
    path: 'product_other/:id',
    canActivate: [AuthGaurd],
    component: ProductInputOtherComponent,
  },
  {
    path: 'product_tank/:id',
    canActivate: [AuthGaurd],
    component: ProductInputTankComponent,
  },

  {
    path: 'product_other/:id/:cid',
    canActivate: [AuthGaurd],
    component: ProductInputOtherComponent,
  },
  {
    path: 'product_tank/:id/:cid',
    canActivate: [AuthGaurd],
    component: ProductInputTankComponent,
  },

  { path: 'addons', canActivate: [AuthGaurd], component: AddonListComponent },
  {
    path: 'addon_input/:id',
    canActivate: [AuthGaurd],
    component: AddonInputComponent,
  },

  { path: 'user_list', canActivate: [AuthGaurd], component: UserListComponent },
  { path: 'orders', canActivate: [AuthGaurd], component: OrderListComponent },
  {
    path: 'pending-order-list',
    canActivate: [AuthGaurd],
    component: PendingOrderListPageComponent,
  },

  {
    path: 'offer',
    canActivate: [AuthGaurd],
    component: OfferListPageComponent,
  },

  {
    path: 'order_details/:id',
    canActivate: [AuthGaurd],
    component: OrderDetailsComponent,
  },
  {
    path: 'recommendations',
    canActivate: [AuthGaurd],
    component: RecommendationListComponent,
  },
  {
    path: 'admin/merchantOrders',
    canActivate: [AuthGaurd],
    component: MerchantOrdersComponent,
  },
  {
    path: 'admin/user-details/:id',
    canActivate: [AuthGaurd],
    component: UserDetailsComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'auction',
    canActivate: [AuthGaurd],
    loadChildren: () =>
      import('./pages/auction-page/auction-page.module').then(
        (m) => m.AuctionPageModule
      ),
  },
  {
    path: 'campaign',
    canActivate: [AuthGaurd],
    loadChildren: () =>
      import('./pages/campaign/campaign.module').then((m) => m.CampaignModule),
  },

  {
    path: shortPath.merchantDeliveryLogic,
    canActivate: [AuthGaurd],
    loadChildren: () =>
      import(
        './pages/merchant-delivery-logic-page/merchant-delivery-logic-page.module'
      ).then((m) => m.MerchantDeliveryLogicPageModule),
  },
];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
