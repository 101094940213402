<div class="input-group">
  <span [owlDateTimeTrigger]="dt" class="input-group-addon"><i class="fa fa-calendar"></i></span>
  <input
      [owlDateTimeTrigger]="dt" [owlDateTime]="dt"
      [(ngModel)]="inputModel"
      placeholder="{{placeholder}}"
      [min]='min' [max]='max'
      readonly
      class="form-control">
</div>
<owl-date-time #dt [stepMinute]="15" [hour12Timer]='true' (afterPickerClosed)="onChange()"></owl-date-time>