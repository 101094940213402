import { Component, OnInit } from '@angular/core';
import { ApiMessageService, AppStateService } from './services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  constructor(
    public apiMessageService: ApiMessageService,
    private appStateService: AppStateService
  ) {}
  ngOnInit() {
    const token = localStorage.getItem('aqua_auth_token') ?? '';
    this.appStateService.userToken$.next(token);
  }
}
