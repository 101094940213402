<div class="main-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="alert alert-green" *ngIf="success_msg != ''">
          <span>{{ success_msg }}</span>
        </div>

        <div class="card">
          <div class="card-header card-header-danger">
            <h4 class="card-title">Orders Basic Details</h4>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th class="text-primary">Order-ID</th>
                    <th># {{ id }}</th>
                    <th class="text-primary">Customer Name</th>
                    <th class="text-capitalize">{{ customer_name }}</th>
                  </tr>
                  <tr>
                    <th class="text-primary">Shipping Address</th>
                    <th>{{ shipping_address }}</th>
                    <th class="text-primary">Billing Address</th>
                    <th>{{ billing_address }}</th>
                  </tr>
                  <tr>
                    <th class="text-primary">Shipping Email</th>
                    <th>{{ shipping_email }}</th>
                    <th class="text-primary">Billing Email</th>
                    <th>{{ billing_email }}</th>
                  </tr>
                  <tr>
                    <th class="text-primary">Order Currency</th>
                    <th>{{ currency }}</th>
                    <th class="text-primary">Sub Total</th>
                    <th>{{ currency }} {{ sub_total | number: '1.2-2' }}</th>
                  </tr>
                  <tr *ngIf="offerCodeDetails">
                    <th class="text-primary">Coupon Code</th>
                    <th>{{ offerCodeDetails.offerCode }}</th>
                    <th class="text-primary">Coupon Code Discount</th>
                    <th>
                      {{ currency }}
                      {{
                        offerCodeDetails.offerDiscountAmount | number: '1.2-2'
                      }}
                    </th>
                  </tr>
                  <tr>
                    <th class="text-primary">Delivery Discount</th>
                    <th>
                      {{ currency }}
                      {{
                        deliveryDiscountAmount
                          ? deliveryDiscountAmount
                          : (0 | number: '1.2-2')
                      }}
                    </th>
                  </tr>
                  <tr>
                    <th class="text-primary">Shipping Total</th>
                    <th>
                      {{ currency }}
                      <span *ngIf="shipping_total === adjustedShippingAmount">{{
                        shipping_total | number: '1.2-2'
                      }}</span>
                      <span *ngIf="shipping_total !== adjustedShippingAmount"
                        ><span class="strike">{{
                          shipping_total | number: '1.2-2'
                        }}</span
                        >&nbsp;<span>{{
                          adjustedShippingAmount | number: '1.2-2'
                        }}</span></span
                      >
                    </th>
                    <th class="text-primary">Total</th>
                    <th>{{ currency }} {{ total | number: '1.2-2' }}</th>
                  </tr>
                  <tr>
                    <th class="text-primary">Payment Status</th>
                    <th
                      class=" payment_status-{{
                        payment_status
                      }} text-capitalize"
                    >
                      {{ payment_status }}
                    </th>
                    <th class="text-primary">Order Status</th>
                    <th *ngIf="whole_order_status == 'in_progress'">
                      <button
                        mat-raised-button
                        type="button"
                        (click)="order_status('confirmed')"
                        [disabled]="button_disabled"
                        class="btn btn-success"
                      >
                        Confirm</button
                      >&nbsp;&nbsp;
                      <button
                        mat-raised-button
                        type="button"
                        (click)="cancelShowPopup()"
                        [disabled]="button_disabled"
                        class="btn btn-warning"
                      >
                        Cancel
                      </button>
                      <span
                        *ngIf="error_msg != ''"
                        style="color: red; font-size: 14px"
                        >&nbsp;{{ error_msg }}</span
                      >
                    </th>
                    <th
                      *ngIf="whole_order_status != 'in_progress'"
                      class=" whole_order_status-{{
                        whole_order_status
                      }} text-capitalize"
                    >
                      {{ whole_order_status }} &nbsp;&nbsp;
                      <span
                        style="color: blue; cursor: pointer"
                        *ngIf="whole_order_status == 'cancelled'"
                        (click)="cancelShowPopup()"
                        >show reason</span
                      >
                    </th>
                  </tr>
                  <tr>
                    <th class="text-primary">Order On</th>
                    <th>{{ created_on | date: 'medium' }}</th>
                    <th class="text-primary">Update On</th>
                    <th>{{ updated_on | date: 'medium' }}</th>
                  </tr>
                  <tr>
                    <th class="text-primary">Remarks</th>
                    <th>{{ remarks }}</th>
                    <th class="text-primary">Order Status Remarks</th>
                    <th>{{ orderRemarks }}</th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header card-header-danger">
            <h4 class="card-title">Orders Details (Product List)</h4>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table">
                <thead class="text-primary">
                  <tr>
                    <th>Product-ID</th>
                    <th>Product Name</th>
                    <th>Image</th>
                    <th>Price</th>
                    <th>Quantity</th>
                    <th>Option Details</th>
                    <th>Delivery Type</th>
                    <th>Vendor Name</th>
                    <th>Delivery Remarks</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of list">
                    <td>{{ row.product_id }}</td>
                    <td class="text-capitalize">{{ row.product_name }}</td>
                    <td>
                      <img
                        *ngIf="
                          row.product_image != '' && row.product_image != null
                        "
                        src="{{ row.product_image }}"
                        width="80px;"
                        height="80px;"
                      />
                    </td>
                    <td>
                      {{ currency }} {{ row.product_price | number: '1.2-2' }}
                    </td>
                    <td>{{ row.quantity }}</td>
                    <td>
                      {{ row.addon_name }} / {{ currency }}
                      {{ row.option_price | number: '1.2-2' }}
                    </td>
                    <td>
                      {{ row.delivery_type }} / {{ currency }}
                      {{ row.delivery_price | number: '1.2-2' }}
                    </td>
                    <td class="text-capitalize">{{ row.vendor_name }}</td>
                    <td>{{ row.delivery_remarks }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="{{ cancel_popup }}"
  id="cancelDescriptionModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
  *ngIf="cancel_popup == 'show'"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5
          class="modal-title"
          id="exampleModalLabel"
          *ngIf="whole_order_status == 'in_progress'"
        >
          Are you sure ? Cancel this order.
        </h5>
        <h5
          class="modal-title"
          id="exampleModalLabel"
          *ngIf="whole_order_status == 'cancelled'"
        >
          Order cancellation reason
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="cancelClosePopup()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" *ngIf="whole_order_status == 'in_progress'">
        <div class="form-group">
          <textarea
            class="form-control"
            placeholder="Cancellation Reason"
            [(ngModel)]="cancellation_rason"
          ></textarea>
          <span class="" style="color: red; font-size: 12px">{{
            cancellation_error_msg
          }}</span>
        </div>
        <div class="d-flex align-items-center justify-content-end pt-3">
          <button
            type="button"
            class="btn btn-secondary"
            data-dismiss="modal"
            (click)="cancelClosePopup()"
          >
            No
          </button>
          <button
            type="button"
            class="btn btn-primary"
            (click)="order_status('cancelled')"
          >
            Yes
          </button>
        </div>
      </div>
      <div class="modal-body" *ngIf="whole_order_status == 'cancelled'">
        <p>{{ cancellation_rason }}</p>
      </div>
    </div>
  </div>
</div>
