import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { filter, map, tap } from 'rxjs/operators';
import {
  ApiResponseTrackerService,
  IRequestMethod,
} from '../services/api-response-tracker/api-response-tracker.service';
import { AppStateService } from '../services';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class HttpRequestInterceptor implements HttpInterceptor {
  constructor(
    private apiResponseTrackerService: ApiResponseTrackerService,
    private appStateService: AppStateService,
    private router: Router
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // return of(undefined);
    const jwtToken = localStorage.getItem('access-token');

    const body = { ...req.body };

    let clonedAuthReq = req.clone({
      setHeaders: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json; charset=utf-8',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Accept: 'application/json',
        // eslint-disable-next-line @typescript-eslint/naming-convention
      },
      body,
    });

    if (jwtToken) {
      clonedAuthReq = req.clone({
        headers: req.headers.append('x-access-token', jwtToken),
      });
    }
    return next.handle(clonedAuthReq).pipe(
      filter((event) => event instanceof HttpResponse),
      tap((event: HttpResponse<any>) => {
        this.apiResponseTrackerService.saveResponse({
          url: event.url,
          data: event.body,
          method: clonedAuthReq.method as IRequestMethod,
        });
        if (event.body.message === 'Unauthorized') {
          localStorage.clear();
          this.appStateService.userToken$.next('');
          this.router.navigate(['/login']);
        }
      })
    );
  }
}
