<div class="main-content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6 m-auto">
          <div class="card">
            <div class="card-header card-header-danger">
              <h4 class="card-title">Addon Input</h4>
            </div>
            <div class="card-body">
        <form [formGroup]="inputForm" (ngSubmit)="input()">
                <div class="row">
                  <div class="col-md-12">
                    <mat-form-field class="example-full-width">
                      <input matInput placeholder="Addon Name" formControlName="product_name" [(ngModel)]=product_name required >
                    </mat-form-field>
                    <mat-hint *ngIf="isSubmitted && inputFrm.product_name.errors">  
                        <span class="text-danger" *ngIf="inputFrm.product_name.errors.required"><img src="../../../assets/images/info-icon-red.svg" alt="" class="info" />This field is required.</span>
                    </mat-hint>
                  </div>
                </div>
                  <div class="row">
                  <div class="col-md-4">
                      <mat-form-field class="example-full-width">
                        <input matInput placeholder="$ Price" formControlName="price" [(ngModel)]=price required >
                      </mat-form-field>
                      <mat-hint *ngIf="isSubmitted && inputFrm.price.errors">  
                          <span class="text-danger" *ngIf="inputFrm.price.errors.required"><img src="../../../assets/images/info-icon-red.svg" alt="" class="info" />This field is required.</span>
                          <span class="text-danger" *ngIf="inputFrm.price.errors.pattern "><img src="../../../assets/images/info-icon-red.svg" alt="" class="info" />Price must be a numerical value.</span>
                      </mat-hint> 
                  </div>
                  <div class="col-md-4">
                    <mat-form-field class="example-full-width">
                      <input matInput placeholder="$ Delivery Fees" formControlName="delivery_fees" [(ngModel)]=delivery_fees required >
                    </mat-form-field>
                    <mat-hint *ngIf="isSubmitted && inputFrm.delivery_fees.errors">  
                        <span class="text-danger" *ngIf="inputFrm.delivery_fees.errors.required"><img src="../../../assets/images/info-icon-red.svg" alt="" class="info" />This field is required.</span>
                        <span class="text-danger" *ngIf="inputFrm.delivery_fees.errors.pattern "><img src="../../../assets/images/info-icon-red.svg" alt="" class="info" />Delivery Fees must be a numerical value.</span>
                    </mat-hint> 
                  </div>
                  <div class="col-md-4">
                      <mat-form-field class="example-full-width">
                        <input matInput placeholder="Quantity" formControlName="qty" [(ngModel)]=qty required >
                      </mat-form-field>
                      <mat-hint *ngIf="isSubmitted && inputFrm.qty.errors">  
                          <span class="text-danger" *ngIf="inputFrm.qty.errors.required"><img src="../../../assets/images/info-icon-red.svg" alt="" class="info" />This field is required.</span>
                          <span class="text-danger" *ngIf="inputFrm.qty.errors.pattern "><img src="../../../assets/images/info-icon-red.svg" alt="" class="info" />Quantity must be in numbers.</span>
                      </mat-hint>
                  </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                      <mat-form-field class="example-full-width">
                        <input matInput placeholder="Description" formControlName="more_description" [(ngModel)]=more_description required >
                      </mat-form-field>
                      <mat-hint *ngIf="isSubmitted && inputFrm.more_description.errors">  
                          <span class="text-danger" *ngIf="inputFrm.more_description.errors.required"><img src="../../../assets/images/info-icon-red.svg" alt="" class="info" />This field is required.</span>
                      </mat-hint>
                    </div>
                </div>

                <div class="row">
                     <div class="col-md-12">
                        <!-- <label class="input_label">Vendor</label> -->
      
                             <mat-form-field class=" w-100">
                               <mat-label>Vendor</mat-label>
                                   <mat-select [(ngModel)]=vendor_id [ngModelOptions]="{standalone: true}" (selectionChange)="slectVendor($event.value)">
                                     <mat-option  *ngFor="let row of vendors" [(value)]="row.id" >
                                        {{row.first_name}}
                                    </mat-option >
                                </mat-select>
                            </mat-form-field>  
                            <mat-hint *ngIf="vendor_err_msg">  
                                <span class="text-danger" *ngIf="vendor_err_msg"><img src="../../../assets/images/info-icon-red.svg" alt="" class="info" />{{vendor_err_msg}}</span>
                              </mat-hint>  
                        </div>
                </div>       
                <div class="row">
                <div class="col-md-6">
                          <mat-form-field class=" w-100">
                              <mat-label>Category</mat-label>
                                <mat-select [(ngModel)]=category_id [ngModelOptions]="{standalone: true}" (selectionChange)="slectCategory($event.value)">
                                  <mat-option  *ngFor="let row of categories" [(value)]="row.id" >
                                    {{row.category_name}}
                                </mat-option >
                            </mat-select>
                        </mat-form-field> 
                        <mat-hint *ngIf="category_err_msg">  
                            <span class="text-danger" *ngIf="category_err_msg"><img src="../../../assets/images/info-icon-red.svg" alt="" class="info" />{{category_err_msg}}</span>
                          </mat-hint>   
                </div>
                <div class="col-md-6">
                  <mat-form-field class=" w-100">
                      <mat-label>Sub-Category</mat-label>
                        <mat-select [(ngModel)]=subcategory_id [ngModelOptions]="{standalone: true}" (selectionChange)="slectSubCategory($event.value)">
                          <mat-option  *ngFor="let row of subcategories" [(value)]="row.id" >
                            {{row.category_name}}
                        </mat-option >
                    </mat-select>
                </mat-form-field> 
                <mat-hint *ngIf="subcategory_err_msg">  
                    <span class="text-danger" *ngIf="subcategory_err_msg"><img src="../../../assets/images/info-icon-red.svg" alt="" class="info" />{{subcategory_err_msg}}</span>
                  </mat-hint>   
              </div>
              </div>
                <div class="row">
                      <div class="col-md-12">
                          <!-- <label class="input_label">Country</label> -->
        
                                <mat-form-field class=" w-100">
                                    <mat-label>Country</mat-label>
                                      <mat-select [(ngModel)]=countryID [ngModelOptions]="{standalone: true}" (selectionChange)="slectCountry($event.value)">
                                        <mat-option  *ngFor="let row of countries" [(value)]="row.id" >
                                          {{row.text}}
                                      </mat-option >
                                  </mat-select>
                              </mat-form-field>  
                              <mat-hint *ngIf="country_err_msg">  
                                  <span class="text-danger" *ngIf="country_err_msg"><img src="../../../assets/images/info-icon-red.svg" alt="" class="info" />{{country_err_msg}}</span>
                                </mat-hint>  
                      </div>
                </div>
                 - 
                <div class="row">
                  <div class="col-md-12">
                    <div class="row" style="margin: 20px auto;">
                      <div class="col-md-12">
                        <img style="max-height: 100px;" class="img" src="{{ product_image_url }}" />
                      </div>
                      <div class="col-md-12">
                        <button class="btn btn-danger" (click)="fileInput.click()" type="button">
                          <span>Upload Image</span>
                          <input #fileInput type="file" (change)="onFileInput($event,$event.target.files)" style="display:none;" accept="image/*" />
                        </button>
                        <br><span class="text-danger" *ngIf="image_err_msg" ><img src="../../../assets/images/info-icon-red.svg" alt="" class="info" />{{image_err_msg}}</span>
                      </div>
                    </div>
                  </div>
                </div>

             
                    
                <span class="text-danger">{{ error }}</span>
                <span class="text-success">{{ success }}</span>
                <button mat-raised-button type="submit"  [disabled]="button_disabled" class="btn btn-danger pull-right">SAVE</button>
                <div class="clearfix"></div>
               </form> 
            </div>
          </div>
        </div>
     
   
      </div>
    </div>
  </div>
  
