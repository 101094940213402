<div fxLayout="row">
  <main-nav fxFlex="100">
    <div fxLayout="column">
      <div fxLayout="row" fxLayoutAlign="end center">
        <div
          fxFlex="300px"
          fxFlex.xs="100"
          class="alert api-alert-container"
          [ngClass]="{
            'alert-success': apiMessageService.messageType === 'success',
            'alert-danger': apiMessageService.messageType === 'error'
          }"
          role="alert"
          *ngIf="apiMessageService.message"
        >
          <div>{{ apiMessageService.message }}</div>

          <button
            mat-button
            class="btn btn-lg"
            *ngIf="apiMessageService.actionButtonConfig"
            [routerLink]="[apiMessageService.actionButtonConfig.navigateTo]"
          >
            {{ apiMessageService.actionButtonConfig.text }}
          </button>
        </div>
      </div>

      <router-outlet></router-outlet>
    </div>
  </main-nav>
</div>
