import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';

import { RequestService } from '../../services/request/request.service';

declare var $: any;
@Component({
  selector: 'app-product-addons',
  templateUrl: './product-addons.component.html',
  styleUrls: ['./product-addons.component.css'],
})
export class ProductAddonsComponent implements OnInit {
  checked = false;
  error = '';
  success = '';
  error2 = '';
  success2 = '';
  errorUpload = '';
  successUpload = '';
  addon_name = '';
  price: any;
  app_url = environment.backendURL;
  inputForm: FormGroup;
  isSubmitted = false;

  success_msg = '';
  error_msg = '';
  list: any;
  token = '';
  id = 0;
  pid = 0;
  msg: any;
  // product_id:any;
  button_disabled = false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private auth: RequestService
  ) {
    this.pid = parseInt(this.route.snapshot.paramMap.get('pid'), 10);
    this.id = parseInt(this.route.snapshot.paramMap.get('id'), 10);
    this.msg = this.route.snapshot.paramMap.get('msg');
  }

  ngOnInit(): void {
    // this.success = 'k success';
    // this.getCategories();
    // this.tnc_accept =true;
    if (this.msg === 'msg') {
      this.success_msg = 'Record saved successfully.';
    }
    this.inputForm = this.formBuilder.group({
      addon_name: ['', Validators.required],
      price: ['', [Validators.required, Validators.pattern('^[0-9.]*$')]],
    });
    this.token = localStorage.getItem('aqua_auth_token');
    this.auth
      .getService('/api/admin/addon_list?pid=' + this.pid, this.token)
      .subscribe((res: any) => {
        if (res.result_code == '1') {
          this.list = res.data;
          console.log(this.list);
        } else {
          console.log(res);
        }
      });

    if (this.id != 0) {
      this.auth
        .getService('/api/admin/addon_row?id=' + this.id, this.token)
        .subscribe((res: any) => {
          if (res.status == '1') {
            // console.log('kk kk');
            // console.log(res);
            this.addon_name = res.data.addon_name.trim();
            this.price = res.data.addon_price; // parseInt(res.data.parent_id.trim());
            this.pid = res.data.product_id;
          } else {
            // localStorage.clear();
            this.router.navigate(['/products']);
          }
        });
    }
    // ------------------
    setTimeout(() => {
      this.success_msg = '';
    }, 3000);
  }

  get inputFrm() {
    return this.inputForm.controls;
  }

  input() {
    this.isSubmitted = true;
    if (this.inputForm.invalid) {
      return;
    }
    this.button_disabled = true;

    const data = {
      addon_name: this.addon_name,
      price: this.price,
      product_id: this.pid,
      addon_id: this.id,
    };
    console.log(data, 'data');

    this.token = localStorage.getItem('aqua_auth_token');
    this.auth
      .postAuthService('/api/admin/addon_input', data, this.token)
      .subscribe((res: any) => {
        if (res.status == '1' || res.status == true) {
          window.location.replace(
            this.app_url +
              '/product_addons/' +
              this.pid +
              '/' +
              this.id +
              '/msg'
          );
        } else {
          this.error = res.message;
          this.button_disabled = false;
        }
      });
  }
  // ---------------------
}
