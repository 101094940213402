import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { defaultProfilePic } from '../../config';
import {
  emailPattern,
  phoneNumberPattern,
  sixDigitPostalPattern,
} from '../../regex';
import { RequestService } from '../../services/request/request.service';
import { passwordValidator } from './password.validator';
import { IVendorInfo } from './vendor.model';
declare var $: any;
@Component({
  selector: 'app-vendor-detail',
  templateUrl: './vendor-detail.component.html',
  styleUrls: ['./vendor-detail.component.css'],
})
export class VendorDetailComponent implements OnInit {
  checked = false;
  error = '';
  success = '';
  status = 'active';
  is_star_seller = 'No';
  inputForm: FormGroup;
  isSubmitted = false;
  countries = [];
  countryID = 2;
  token = '';
  vendorId = 0;
  email_msg = '';
  country_err_msg = '';
  is_star_seller_err_msg = '';
  status_err_msg = '';
  button_disabled = false;
  defaultProfilePic = defaultProfilePic;
  profilePic = defaultProfilePic;
  uploadImageMessage = '';
  fileToUpload: File;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private auth: RequestService
  ) {}

  ngOnInit(): void {
    this.vendorId = parseInt(this.route.snapshot.paramMap.get('id'), 10);
    this.getCountryList();

    let formData: IVendorInfo = {
      first_name: ['', Validators.required],
      last_name: [''],
      street: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      postalCode: [
        '',
        [Validators.required, Validators.pattern(sixDigitPostalPattern)],
      ],
      email: ['', [Validators.required, Validators.pattern(emailPattern)]],
      mobile: [
        '',
        [
          Validators.required,
          Validators.pattern(phoneNumberPattern),
          Validators.minLength(8),
        ],
      ],
      password: ['', [passwordValidator]],
      standardDeliveryFee: ['0', [Validators.required]],
    };
    this.inputForm = this.formBuilder.group(formData);

    if (this.vendorId != 0) {
      this.auth
        .getService(
          '/api/admin/get_row?t=aqua_users&id=' + this.vendorId,
          this.token
        )
        .subscribe((res: any) => {
          if (res.status == '1') {
            formData = {
              first_name: res.data.first_name.trim() || '',
              last_name: res.data.last_name.trim() || '',
              street: res.data.address_line1.trim() || '',
              city: res.data.city.trim() || '',
              state: res.data.state.trim() || '',
              email: res.data.email.trim() || '',
              mobile: res.data.phone_num.trim() || '',
              password: '',
              standardDeliveryFee: res.data.standard_delivery_fee || '0',
              postalCode: res.data.postal_code || '',
            };
            this.inputForm.patchValue(formData);

            this.status = res.data.status.trim();
            this.is_star_seller = res.data.is_star_seller.trim();
            this.countryID = +(res.data.country + '')?.trim();
            this.profilePic = res.data.profile_pic?.trim() || this.profilePic;
          } else {
            // localStorage.clear();
            this.router.navigate(['/vendors']);
          }
        });
    }
    // ------------------
  }

  get inputFormControls() {
    return this.inputForm.controls;
  }

  get password(): any {
    return this.inputForm.controls.password;
  }
  // ---------------------

  slectOption(e) {
    this.countryID = e;
    this.country_err_msg = '';
  }

  slectStatus(e) {
    this.status = e;
    this.status_err_msg = '';
  }
  slect_is_star_seller(e) {
    this.is_star_seller = e;
    this.is_star_seller_err_msg = '';
  }

  getCountryList() {
    this.token = localStorage.getItem('aqua_auth_token');
    this.auth.getService('/api/countries', this.token).subscribe((res: any) => {
      if (res.result_code == '1') {
        this.countries = res.data;
        // console.log(this.countries);
      }
    });
  }

  get standardDeliveryFee(): AbstractControl {
    return this.inputFormControls.standardDeliveryFee;
  }

  // profile pic update
  uploadProfilePicture(files: FileList) {
    this.uploadImageMessage = 'uploading..';

    this.fileToUpload = files.item(0);

    const fd = new FormData();
    fd.append('profile_pic', this.fileToUpload);

    this.auth
      .putAuthService(
        `/api/users/upload_profile_image?merchantId=${this.vendorId}`,
        fd
      )
      .subscribe((res: any) => {
        if (res.result_code === 200) {
          this.uploadImageMessage = 'uploaded';
          this.profilePic = res.data.profile_pic;
          setTimeout(() => {
            this.uploadImageMessage = '';
          }, 2000);
        } else {
          this.uploadImageMessage = res.message;
          setTimeout(() => {
            this.uploadImageMessage = '';
          }, 2000);
        }
      });
  }
  // --------------------------------------

  // Delete profile pic
  deleteProfilePicture() {
    this.uploadImageMessage = 'deleting..';

    this.auth
      .putAuthService(
        `/api/users/delete_profile_image?merchantId=${this.vendorId}`,
        null
      )
      .subscribe((res: any) => {
        if (res.result_code === 200) {
          this.uploadImageMessage = 'deleted';
          this.profilePic = this.defaultProfilePic;
          setTimeout(() => {
            this.uploadImageMessage = '';
          }, 2000);
        } else {
          this.uploadImageMessage = res.message;
          setTimeout(() => {
            this.uploadImageMessage = '';
          }, 2000);
        }
      });
  }

  input() {
    this.isSubmitted = true;
    if (this.inputForm.invalid) {
      return;
    }
    if (this.countryID == 0) {
      this.country_err_msg = 'Please select country';
      return;
    } else this.country_err_msg = '';

    this.button_disabled = true;

    const data = {
      first_name: this.inputFormControls.first_name.value,
      last_name: this.inputFormControls.last_name.value,
      city: this.inputFormControls.city.value,
      state: this.inputFormControls.state.value,
      email: this.inputFormControls.email.value,
      mobile: this.inputFormControls.mobile.value,
      street: this.inputFormControls.street.value,
      password: this.inputFormControls.password.value,
      standardDeliveryFee: this.standardDeliveryFee.value,
      postalCode: this.inputFormControls.postalCode.value,
      status: this.status,
      is_star_seller: this.is_star_seller,
      country: this.countryID,
      vid: this.vendorId,
    };

    this.auth
      .postAuthService('/api/admin/vendor_input', data, this.token)
      .subscribe((res: any) => {
        if (res.status == '1' || res.status == true) {
          this.router.navigate(['/vendors'], {
            state: { success_msg: 'Record saved successfully.' },
          });
        } else {
          if (res.message.trim() == 'Email already exist') {
            this.email_msg = res.message;
          } else this.error = res.message;

          this.button_disabled = false;

          setTimeout(() => {
            this.error = '';
            this.email_msg = '';
            this.country_err_msg = '';
          }, 2000);
        }
      });
  }
}
