import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { environment } from '../../../environments/environment';

import { RequestService } from '../../services/request/request.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';

declare var $: any;
@Component({
  selector: 'app-product-input-other',
  templateUrl: './product-input-other.component.html',
  styleUrls: ['./product-input-other.component.css'],
})
export class ProductInputOtherComponent implements OnInit {
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '200px',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: false,
    showToolbar: false,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' },
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [['bold', 'italic'], ['fontSize']],
  };

  filesList: File[] = [];
  @ViewChild('clickOnDocumentTab', { static: false })
  clickOnDocumentTab: ElementRef;
  form_submit_btn_name = 'Save';
  other_img_err_msg = '';
  other_vdo_err_msg = '';
  other_files_db: any;
  other_videos_db: any;
  option_submit_btn = 'Add';
  list: any;
  other_files = [];
  other_videos = [];

  videoFileToUpload = [];
  htmlContent: any;
  checked = false;
  error = '';
  success = '';
  option_success = '';
  option_error = '';
  addon_name = '';
  addon_price: any;
  addon_id = 0;
  app_url = environment.backendURL;
  product_name = '';
  price: any;
  sell_price: any;
  delivery_standard: any;
  capacity_in_gallons: any;
  delivery_express: any;
  qty: any;
  cid: any;
  size = '';
  sizes = ['Small', 'Medium', 'Large'];

  is_featured = 'No';
  is_exclusive = 'No';
  available_in_build_aquarium = 'No';
  status = 'active';
  sold_count: any;
  rating: any;
  care_level = '';
  care_levels = ['Low', 'Medium', 'High'];

  temperament = '';
  Temperaments = ['Aggressive', 'Semi Aggressive', 'Peaceful'];

  water_condition = '';
  water_conditions = ['Low PH', 'High PH'];

  more_description = '';

  inputForm: FormGroup;
  isSubmitted = false;

  inputOptionForm: FormGroup;
  isSubmittedOption = false;

  countries = [];
  countryID = 0;

  vendors = [];
  vendor_id = 0;
  select_test: any;
  categories = [];
  category_id = 0;

  subcategory_id = 0;
  subcategories = [];

  country_err_msg = '';
  vendor_err_msg = '';
  category_err_msg = '';
  subcategory_err_msg = '';
  size_err_msg = '';
  carelvl_err_msg = '';
  temperament_err_msg = '';
  waterconditon_err_msg = '';
  sell_price_err_msg = '';
  button_disabled = false;
  button_disabled_option = false;
  token = '';
  id = 0;
  is_featured_err_msg = '';
  image: any;
  image_ext = '';
  image_err_msg = '';
  image_submit = false;
  product_image: File = null;
  product_image_url: any;
  tagsData = [];
  user_id: string;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private auth: RequestService
  ) {
    this.id = parseInt(this.route.snapshot.paramMap.get('id'), 10);
    this.cid = parseInt(this.route.snapshot.paramMap.get('cid'), 10);
  }

  ngOnInit(): void {
    this.user_id = localStorage.getItem('aqua_userid');
    this.del_temp_data();
    this.getcountry();
    this.getcategories();

    this.getsubcategories();
    this.getProductOption();
    this.inputForm = this.formBuilder.group({
      product_name: ['', Validators.required],
      price: ['', [Validators.pattern('^[0-9.]*$')]],
      sell_price: ['', [Validators.required, Validators.pattern('^[0-9.]*$')]],
      delivery_standard: ['', [Validators.pattern('^[0-9.]*$')]],
      delivery_express: ['', [Validators.pattern('^[0-9.]*$')]],
      qty: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
      more_description: ['', Validators.required],
      sold_count: ['', ''],
      rating: ['', ''],
      searchScore: [
        50,
        [
          Validators.required,
          Validators.pattern('^[0-9]*$'),
          Validators.max(100),
          Validators.min(0),
        ],
      ],
      isPromoted: [false, [Validators.required]],
    });

    this.inputOptionForm = this.formBuilder.group({
      addon_name: ['', Validators.required],
      addon_price: [
        '',
        [Validators.required, Validators.pattern('^[0-9.-]*$')],
      ],
    });

    if (!this.id) {
      return;
    }
    this.auth
      .getService(
        '/api/admin/product_row?t=product&id=' + this.id + '&cid=' + this.cid,
        this.token
      )
      .subscribe((res: any) => {
        if (res.status == '1') {
          console.log(res);
          this.product_name = res.data.product_name.trim();
          this.price = res.data.price;
          this.qty = res.data.qty;
          this.vendor_id = res.data.vendor_id;
          this.category_id = res.data.category_id;
          this.status = res.data.status.trim();
          this.inputForm.get('searchScore').patchValue(+res.data.s_score);
          this.inputForm.get('isPromoted').patchValue(res.data.is_promoted);
          this.more_description = res.data.more_description.trim();
          this.countryID = res.data.country_id;
          this.getsubcategories();

          if (res.data.product_image.trim()) {
            this.image_submit = true;
            this.product_image_url = res.data.product_image;
            this.image_err_msg = '';
          }

          this.is_featured = res.data.is_featured.trim();
          this.is_exclusive = res.data.is_exclusive.trim();
          this.available_in_build_aquarium =
            res.data.available_in_build_aquarium.trim();

          if (
            res.data.delivery_express_chargess ||
            res.data.delivery_express_charges === 0
          ) {
            this.delivery_express = res.data.delivery_express_charges;
          }

          if (
            res.data.delivery_standard_charges ||
            res.data.delivery_standard_charges === 0
          ) {
            this.delivery_standard = res.data.delivery_standard_charges;
          }

          if (res.data.sell_price || res.data.sell_price === 0) {
            this.subcategory_id = res.data.subcategory_id;
          }

          if (res.data.sell_price) {
            this.sell_price = res.data.sell_price;
          }

          if (res.data.capacity_in_gallons) {
            this.capacity_in_gallons = res.data.capacity_in_gallons;
          }

          if (res.data.tags) {
            this.tagsData = res.data.tags.split(',');
          }

          this.rating = res.data.rating;
          this.sold_count = res.data.sold_count;
          this.getProductImages();
          this.getProductVideos();
          this.getvendors();
        } else {
          this.router.navigate(['/products']);
        }
      });
  }

  get inputFrm() {
    return this.inputForm.controls;
  }
  get inputOptionFrm() {
    return this.inputOptionForm.controls;
  }

  input() {
    if (this.countryID === 0) {
      this.countryID = 2;
    }
    this.isSubmitted = true;
    if (this.inputForm.invalid) {
      return;
    }

    if (this.vendor_id === 0) {
      this.vendor_err_msg = 'Please select';
      return;
    } else {
      this.vendor_err_msg = '';
    }

    if (this.countryID === 0) {
      this.country_err_msg = 'Please select';
      return;
    } else {
      this.country_err_msg = '';
    }

    if (this.category_id === 0) {
      this.category_err_msg = 'Please select';
      return;
    } else {
      this.category_err_msg = '';
    }

    if (this.subcategory_id === 0) {
      this.subcategory_err_msg = 'Please select';
      return;
    } else {
      this.subcategory_err_msg = '';
    }

    if (this.product_image_url === '' || this.image_submit === false) {
      this.image_err_msg = 'Please choose a image';
      console.log(this.image_err_msg);

      console.log(this.product_image_url);
      console.log('img-submit' + this.image_submit);

      return;
    } else {
      this.image_err_msg = '';
    }

    if (this.delivery_express <= 0 || !this.delivery_express) {
      this.delivery_express = 0;
    }

    if (this.price <= 0 || !this.price) {
      this.price = 0;
    }

    if (
      this.price > 0 &&
      parseFloat(this.price) < parseFloat(this.sell_price)
    ) {
      this.sell_price_err_msg = 'Sell price must be less or equal to price';
      return;
    } else {
      this.sell_price_err_msg = '';
    }

    if (this.videoFileToUpload.length > 3) {
      this.other_vdo_err_msg = 'Maximum three videos can upload in one time.';
      return;
    } else {
      this.other_vdo_err_msg = '';
    }

    this.button_disabled = true;

    const data = {
      product_name: this.product_name,
      price: this.price,
      sell_price: this.sell_price,
      qty: this.qty,
      more_description: this.more_description,
      category_id: this.category_id,
      vendor_id: this.vendor_id,
      subcategory_id: this.subcategory_id,
      country_id: this.countryID,
      image: this.image,
      image_ext: this.image_ext,
      delivery_standard: this.delivery_standard,
      delivery_express: this.delivery_express,
      is_featured: this.is_featured,
      is_exclusive: this.is_exclusive,
      available_in_build_aquarium: this.available_in_build_aquarium,
      status: this.status,
      other_files: this.other_files,
      product_id: this.id,
      tags: this.tagsData.length > 0 ? this.tagsData.join(',') : '',
      searchScore: this.inputForm.get('searchScore').value,
      isPromoted: this.inputForm.get('isPromoted').value,
    };
    this.form_submit_btn_name = 'Please wait..';
    this.auth
      .postAuthService('/api/admin/product_input', data, this.token)
      .subscribe((res: any) => {
        if (res.status == '1' || res.status == true) {
          console.log(res.data.id);
          const fd = new FormData();
          if (this.videoFileToUpload.length > 0) {
            for (let j = 0; j < this.videoFileToUpload.length; j++) {
              fd.append('video_' + j, this.videoFileToUpload[j]);
              console.log(fd);
            }
            fd.append('product_id', res.data.id);
            this.auth
              .putAuthService(
                '/api/admin/product_videos_upload',
                fd,
                this.token
              )
              .subscribe((res: any) => {
                if (res.result_code == 1) {
                  this.form_submit_btn_name = 'Save';
                  this.router.navigate(['/products'], {
                    state: { success_msg: 'Record saved successfully.' },
                  });
                }
              });
          } else {
            this.form_submit_btn_name = 'Save';
            this.router.navigate(['/products'], {
              state: { success_msg: 'Record saved successfully.' },
            });
          }
        } else {
          this.error = res.message;
          this.button_disabled = false;
          this.form_submit_btn_name = 'Save';
          setTimeout(() => {
            this.error = '';
          }, 3000);
        }
      });
  }

  delImg(i) {
    const res = this.other_files.splice(i, 1); // ?? What does this do ??
  }

  delVdo(i) {
    console.log(i);
    console.log(this.other_videos[i]);
    console.log(this.videoFileToUpload[i]);
  }

  multiVideos(event, files: FileList) {
    for (let i = 0; i < event.target.files.length; i++) {
      const reader = new FileReader();
      const len = event.target.files[i].name.split('.').length;
      const name = event.target.files[i].name;
      const ext = event.target.files[i].name.split('.')[len - 1].toLowerCase();
      if (event.target.files[i].size / 1024 / 1024 <= 200) {
        if (ext === 'mp4' || ext === 'mov') {
          this.videoFileToUpload.push(event.target.files[i]);

          reader.readAsDataURL(event.target.files[i]);
          reader.onload = () => {
            this.other_videos.push({
              img: reader.result,
              ext: ext,
              name: name,
            });
          };
        } else {
          this.other_vdo_err_msg = 'File format is not valid.';
        }
      } else {
        this.other_vdo_err_msg = 'Upload Max size is 70MB';
      }
    }
    setTimeout(() => {
      this.other_img_err_msg = '';
    }, 2000);
  }

  delImgDB(img_id, type) {
    this.token = localStorage.getItem('aqua_auth_token');
    this.auth
      .getService(
        '/api/admin/product_img_delete?pid=' +
          this.id +
          '&id=' +
          img_id +
          '&type=' +
          type,
        this.token
      )
      .subscribe((res: any) => {
        if (res.result_code == '1') {
          if (type == 0) {
            this.other_files_db = res.data;
          }
          if (type == 1) {
            this.other_videos_db = res.data;
          }
          console.log(this.list);
        }
      });
  }

  slectStatus(e) {
    this.status = e;
  }

  slect_is_featured(e) {
    this.is_featured = e;
  }

  slect_is_exclusive(e) {
    this.is_exclusive = e;
  }

  slect_available_in_build_aquarium(e) {
    this.available_in_build_aquarium = e;
  }

  slectCountry(e) {
    this.countryID = e;
    this.country_err_msg = '';
    this.vendor_id = 0;
    this.getvendors();
  }

  slectVendor(e) {
    this.vendor_id = e;
    this.vendor_err_msg = '';
  }

  slectfeatured(e) {
    this.is_featured = e;
  }

  slectCategory(e) {
    this.category_id = e;
    this.category_err_msg = '';
    this.subcategory_id = 0;
    this.getsubcategories();
  }

  slectSubCategory(e) {
    this.subcategory_id = e;
    this.subcategory_err_msg = '';
  }

  slectSize(e) {
    this.size = e;
    this.size_err_msg = '';
  }

  slectCarelevel(e) {
    this.care_level = e;
    this.carelvl_err_msg = '';
  }

  slectTemperament(e) {
    this.temperament = e;
    this.temperament_err_msg = '';
  }

  slectWaterCondition(e) {
    this.water_condition = e;
    this.waterconditon_err_msg = '';
  }

  getcountry() {
    this.token = localStorage.getItem('aqua_auth_token');
    this.auth.getService('/api/countries', this.token).subscribe((res: any) => {
      if (res.result_code == '1') {
        this.countries = res.data;
      }
    });
  }

  getvendors() {
    this.token = localStorage.getItem('aqua_auth_token');
    this.auth
      .getService(
        '/api/admin/get_active_vendor_list?t=aqua_users&c=' + this.countryID,
        this.token
      )
      .subscribe((res: any) => {
        if (res.result_code == '1') {
          this.vendors = res.data;
        }
      });
  }

  getcategories() {
    this.token = localStorage.getItem('aqua_auth_token');
    this.auth
      .getService('/api/admin/get_parent_categories?type=other', this.token)
      .subscribe((res: any) => {
        if (res.result_code == '1') {
          this.categories = res.data;
        }
      });
  }

  getsubcategories() {
    this.token = localStorage.getItem('aqua_auth_token');
    this.auth
      .getService(
        '/api/admin/get_sub_categories?pid=' + this.category_id,
        this.token
      )
      .subscribe((res: any) => {
        if (res.result_code == '1') {
          this.subcategories = res.data;
        }
      });
  }

  onFileInput(event, files: FileList) {
    const reader = new FileReader();
    const len = event.target.files[0].name.split('.').length;
    const ext = event.target.files[0].name.split('.')[len - 1];
    this.image_ext = ext;
    if (event.target.files[0].size / 1024 / 1024 <= 70) {
      if (
        ext === 'png' ||
        ext === 'PNG' ||
        ext === 'jpg' ||
        ext === 'JPG' ||
        ext === 'jpeg' ||
        ext === 'mp4' ||
        ext === 'MP4' ||
        ext === 'JPEG'
      ) {
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = () => {
          this.image = reader.result;
          this.product_image_url = reader.result;
          this.image_submit = true;
          this.image_err_msg = '';
        };
      } else {
        this.image_err_msg = 'File format is not valid.';
      }
    } else {
      this.image_err_msg = 'Upload Max size is 70MB';
    }
    setTimeout(() => {
      this.image_err_msg = '';
    }, 2000);
  }

  multiFiles(event, files: FileList) {
    console.log(files);
    for (let i = 0; i < event.target.files.length; i++) {
      const reader = new FileReader();
      const len = event.target.files[i].name.split('.').length;
      const ext = event.target.files[i].name.split('.')[len - 1];
      if (event.target.files[i].size / 1024 / 1024 <= 70) {
        if (
          ext === 'png' ||
          ext === 'PNG' ||
          ext === 'jpg' ||
          ext === 'JPG' ||
          ext === 'jpeg' ||
          ext === 'JPEG'
        ) {
          reader.readAsDataURL(event.target.files[i]);
          reader.onload = () => {
            this.other_files.push({
              img: reader.result,
              ext: ext,
              index: this.other_files.length,
            });
          };
        } else {
          this.other_img_err_msg = 'File format is not valid.';
        }
      } else {
        this.other_img_err_msg = 'Upload Max size is 70MB';
      }
    }
    setTimeout(() => {
      this.other_img_err_msg = '';
    }, 2000);
  }

  getProductImages() {
    this.token = localStorage.getItem('aqua_auth_token');
    this.auth
      .getService(
        '/api/admin/product_img_list?pid=' + this.id + '&type=0',
        this.token
      )
      .subscribe((res: any) => {
        if (res.result_code == '1') {
          this.other_files_db = res.data;
        }
      });
  }

  getProductVideos() {
    this.token = localStorage.getItem('aqua_auth_token');
    this.auth
      .getService(
        '/api/admin/product_img_list?pid=' + this.id + '&type=1',
        this.token
      )
      .subscribe((res: any) => {
        if (res.result_code == '1') {
          this.other_videos_db = res.data;
          console.log(this.list);
        }
      });
  }

  del_temp_data() {
    this.token = localStorage.getItem('aqua_auth_token');
    this.auth
      .getService('/api/admin/del_temp_data', this.token)
      .subscribe((res: any) => {
        if (res.result_code == '1') {
          console.log('temp data deleted');
        }
      });
  }

  inputOption() {
    this.isSubmittedOption = true;
    if (this.inputOptionForm.invalid) {
      return;
    }
    this.button_disabled_option = true;

    const data = {
      addon_name: this.addon_name,
      price: this.addon_price,
      product_id: this.id,
      addon_id: this.addon_id,
    };
    console.log(data, 'data');

    this.token = localStorage.getItem('aqua_auth_token');
    this.auth
      .postAuthService('/api/admin/addon_input', data, this.token)
      .subscribe((res: any) => {
        if (res.status == '1' || res.status == true) {
          this.addon_name = '';
          this.addon_price = '';
          this.addon_id = 0;
          this.isSubmittedOption = false;
          this.button_disabled_option = false;
          this.option_success = 'Record saved successfully';
          this.option_submit_btn = 'Add';
          setTimeout(() => {
            this.option_success = '';
          }, 2000);
          this.getProductOption();
        } else {
          this.option_error = res.message;
          this.button_disabled_option = false;
        }
      });
  }

  optionEdit(option_id) {
    this.token = localStorage.getItem('aqua_auth_token');
    this.auth
      .getService('/api/admin/addon_row?id=' + option_id, this.token)
      .subscribe((res: any) => {
        if (res.status == '1') {
          this.addon_name = res.data.addon_name.trim();
          this.addon_price = res.data.addon_price;
          this.addon_id = option_id;
          this.option_submit_btn = 'Edit';
        } else {
          this.router.navigate(['/products']);
        }
      });
  }

  optionDel(option_id) {
    this.token = localStorage.getItem('aqua_auth_token');
    this.auth
      .getService(
        '/api/admin/addon_delete?id=' + option_id + '&pid=' + this.id,
        this.token
      )
      .subscribe((res: any) => {
        if (res.result_code == '1') {
          this.list = res.data;
          console.log(this.list);
        }
      });
  }

  getProductOption() {
    this.token = localStorage.getItem('aqua_auth_token');
    this.auth
      .getService('/api/admin/addon_list?pid=' + this.id, this.token)
      .subscribe((res: any) => {
        if (res.result_code == '1') {
          this.list = res.data;
          console.log(this.list);
        }
      });
  }

  addTags(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.tagsData.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  removeTags(tag: string): void {
    const index = this.tagsData.indexOf(tag);

    if (index >= 0) {
      this.tagsData.splice(index, 1);
    }
  }
}
