<div class="main-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
    <div class="alert alert-green" *ngIf="success_msg!=''">
            <span>{{success_msg}}</span>
    </div>
    <!-- <div class="alert alert-danger" *ngIf="error_msg!=''">
            <span>{{error_msg}}</span>
    </div> -->
        <div class="card">
          <div class="card-header card-header-danger">
            <h4 class="card-title ">Category List</h4>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table">
                <thead class=" text-primary">
                  <tr>
                    <th> ID </th>
                    <th> Category Name </th>
                    <th> Image </th>
                    <th> Parent Category Name </th>
                    <th> Category-Type </th>
                    <th> Is Featured </th>
                    <th> Action</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of list">
                    <td> {{ row.id }} </td>
                    <td> {{ row.category_name }}</td>
                    <td> <img *ngIf="row.category_img !='' && row.category_img !=null" src="{{ row.category_img }}" width="80px;" height="80px;"> </td>
                    <td> {{ row.parent_name }}</td>
                    <td>
                      <span *ngIf="row.parent_id == 0"> Main </span>
                      <span *ngIf="row.parent_id != 0"> Sub </span>
                    </td>
                    <td> {{ row.is_featured }}</td>   
                    <td>
                    <a [routerLink]="['/category_input/' + row.id]">
                      <span _ngcontent-gxv-c19="" class="material-icons icon-image-preview">visibility</span>
                    </a>&nbsp;
                    <a *ngIf="row.parent_id != 0" [routerLink]="['/category_input/' + row.id]">
                      <span _ngcontent-gxv-c19="" class="material-icons icon-image-preview">delete</span>
                    </a>
                    </td>
                    <td style="text-transform: capitalize; color: blue;" *ngIf="row.status.trim() == 'active'"> {{ row.status }} </td>
                    <td style="text-transform: capitalize; color: red;" *ngIf="row.status.trim() != 'active'"> {{ row.status }} </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
