import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';

import { AppStateService } from '../../services/app-state/app-state.service';
import { RequestService } from '../../services/request/request.service';

import { NotificationService } from '../../services/notification/notification.service';
import { IOrderDetailEntity } from '../../models';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FormStateService } from '../../services';
@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.css'],
})
export class OrderListComponent implements OnInit {
  checked = false;
  success_msg = '';
  error_msg = '';
  app_url = environment.backendURL;

  list: any[] = []; // Replace 'any' with your specific type for the list items
  currentPage = 1;
  itemsPerPage = 10; // Number of items to display per page
  totalRecords: number;

  filterForm: FormGroup;
  filterOptions = [
    { label: 'Customer Name', value: 'customerName' },
    { label: 'Order Remarks', value: 'orderRemarks' },
    { label: 'Order Status', value: 'orderStatus' },
    { label: 'Remarks', value: 'remarks' },
    { label: 'Order ID', value: 'orderId' },
  ];
  orderStatusOptions: string[] = ['in_progress', 'cancelled', 'confirmed'];
  readonly formInfoForPersistence = {
    name: 'orderList',
  };

  constructor(
    private auth: RequestService,
    private appStateService: AppStateService,
    private notificationService: NotificationService,
    private formBuilder: FormBuilder,
    private formStateService: FormStateService
  ) {}
  ngOnInit(): void {
    const storedFilterType = this.formStateService.getFormValue(
      this.formInfoForPersistence.name,
      'filterType'
    );
    const storedSearchTerm = this.formStateService.getFormValue(
      this.formInfoForPersistence.name,
      'searchTerm'
    );

    const currentPage = this.formStateService.getFormValue(
      this.formInfoForPersistence.name,
      'currentPage'
    );
    this.currentPage = currentPage || 1;
    this.filterForm = this.formBuilder.group({
      filterType: [storedFilterType || 'customerName'],
      searchTerm: [storedSearchTerm || ''],
    });
    this.filterForm.get('filterType').valueChanges.subscribe((_) => {
      console.log(
        'value changes triggered',
        this.filterForm.get('filterType').value
      );
      this.filterForm.get('searchTerm').setValue('');
    });
    this.loadOrders();
  }

  loadOrders(
    options: {
      pageIndex?: number;
      pageSize?: number;
      filterType?: string;
      searchTerm?: string;
    } = {}
  ): void {
    const {
      pageIndex = this.currentPage - 1,
      pageSize = this.itemsPerPage,
      filterType = this.filterForm.get('filterType').value,
      searchTerm = this.filterForm.get('searchTerm').value,
    } = options;

    this.formStateService.setFormValue(
      this.formInfoForPersistence.name,
      'filterType',
      filterType
    );
    this.formStateService.setFormValue(
      this.formInfoForPersistence.name,
      'searchTerm',
      searchTerm
    );
    this.formStateService.setFormValue(
      this.formInfoForPersistence.name,
      'currentPage',
      this.currentPage
    );

    let apiUrl = `/api/v2/admin/order_list?pageIndex=${pageIndex}&pageSize=${pageSize}`;

    if (filterType && searchTerm) {
      apiUrl += `&${filterType}=${searchTerm}`;
    }
    this.auth.getService(apiUrl).subscribe((res: any) => {
      if (res.status === true) {
        this.totalRecords = res.data.totalRecords;
        const orders = res.data.list || [];
        this.list = orders.map((o) => ({
          ...o,
          created_on: this.appStateService.getFormattedDateFromISO({
            dateFormat: 'yyyy-MM-dd HH:mm a',
            isoDateStr: o.created_on,
          }),
        }));
      } else {
        this.notificationService.show({
          type: 'danger',
          message: 'Could not perform this action',
        });
      }
    });
  }

  onPageChange(page: number): void {
    this.currentPage = page;
    this.loadOrders(); // Reload the data for the selected page
  }

  updateRemarks(orderDetail: IOrderDetailEntity, remarks: string) {
    const token = localStorage.getItem('aqua_auth_token');

    this.auth
      .postAuthService(
        '/api/admin/update_remarks',
        { orderId: orderDetail.id, remarks },
        token
      )
      .subscribe((res: any) => {
        if (res.status === true) {
          this.notificationService.show({ message: 'Success' });
        }
      });
  }
  // The following func updates order_remarks
  updateOrderRemarks(orderId: string, remarks: string) {
    const token = localStorage.getItem('aqua_auth_token');
    this.auth
      .postAuthService(
        '/api/admin/update_order_remarks',
        {
          orderId: orderId,
          remarks: remarks,
        },
        token
      )
      .subscribe((res: any) => {
        if (res.status === true) {
          this.notificationService.show({ message: 'Success' });
        }
      });
  }

  resetFilters(): void {
    const firstFilterType = this.filterOptions[0].value;
    this.filterForm.reset({
      filterType: firstFilterType,
    });
    this.currentPage = 1;
    this.formStateService.clearFormState(this.formInfoForPersistence.name);
    this.loadOrders();
  }
}
