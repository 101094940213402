import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

import { RequestService } from '../../services/request/request.service';

@Component({
  selector: 'app-product_addons_list',
  templateUrl: './product_addons_list.component.html',
  styleUrls: ['./product_addons_list.component.css'],
})
export class ProductAddonListComponent implements OnInit {
  list: any;
  token = '';
  checked = false;
  success_msg = '';
  error_msg = '';
  app_url = environment.backendURL;

  constructor(public router: Router, private auth: RequestService) {
    const navigation = this.router.getCurrentNavigation();
    const state = navigation.extras.state as {
      success_msg: string;
      error_msg: string;
    };
    if (state != undefined) {
      if (state.success_msg) {
        this.success_msg = state.success_msg;
        setTimeout(() => {
          this.success_msg = '';
        }, 3000);
      } else if (state.error_msg) {
        this.error_msg = state.error_msg;
        setTimeout(() => {
          this.error_msg = '';
        }, 3000);
      }
    }
  }

  ngOnInit(): void {
    this.token = localStorage.getItem('aqua_auth_token');
    this.auth
      .getService('/api/admin/product_list?t=product', this.token)
      .subscribe((res: any) => {
        if (res.result_code == '1') {
          this.list = res.data;
          console.log(this.list);
        } else {
          console.log(res);
        }
      });
  }
}
