import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
} from 'ng-pick-datetime-ex';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import {
  FishyhubDatetimePickerComponent,
  FishyhubDatetimePickerRendererComponent,
} from './fishyhub-datetime-picker/fishyhub-datetime-picker.component';
import { FooterComponent } from './footer/footer.component';

@NgModule({
  imports: [
    CommonModule,
    Ng2SmartTableModule,
    FormsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
  ],
  declarations: [
    FooterComponent,
    FishyhubDatetimePickerComponent,
    FishyhubDatetimePickerRendererComponent,
  ],
  exports: [
    FooterComponent,
    FishyhubDatetimePickerComponent,
    FishyhubDatetimePickerRendererComponent,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    Ng2SmartTableModule,
  ],
})
export class ReusableComponentsModule {}
