import { Component, OnInit } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';
import { RequestService } from '../../services/request/request.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-vendor-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.css'],
})
export class CategoryListComponent implements OnInit {
  list: any;
  token = '';
  cname = '';
  checked = false;
  success_msg = '';
  error_msg = '';
  app_url = environment.backendURL;

  constructor(
    private router: Router,
    private auth: RequestService,
    private route: ActivatedRoute
  ) {
    this.cname = this.route.snapshot.queryParamMap.get('cname');
    const navigation = this.router.getCurrentNavigation();
    const state = navigation.extras.state as {
      success_msg: string;
      error_msg: string;
    };
    if (state != undefined) {
      if (state.success_msg) {
        this.success_msg = state.success_msg;
        setTimeout(() => {
          this.success_msg = '';
        }, 3000);
      } else if (state.error_msg) {
        this.error_msg = state.error_msg;
        setTimeout(() => {
          this.error_msg = '';
        }, 3000);
      }
    }
  }

  ngOnInit(): void {
    this.token = localStorage.getItem('aqua_auth_token');
    this.auth
      .getService('/api/admin/category_list?cname=' + this.cname, this.token)
      .subscribe((res: any) => {
        if (res.result_code == '1') {
          this.list = res.data;
          console.log(this.list);
        } else {
          console.log(res);
        }
      });
  }
}
