<div class="main-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header card-header-danger">
            <h4 class="card-title">Recommendations Mapping</h4>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table">
                <thead class="text-primary">
                  <tr>
                    <th>Quiz Response</th>
                    <th>Product Tags</th>
                    <th>changes</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of list; let i = index">
                    <td class="text-capitalize">{{ row.key }}</td>
                    <td>
                      <mat-form-field class="example-chip-list">
                        <mat-chip-list #chipList aria-label="Fruit selection">
                          <mat-chip
                            *ngFor="let tag of row.tags"
                            [selectable]="true"
                            [removable]="true"
                            (removed)="removeTags(tag, i)"
                          >
                            {{ tag }}
                            <mat-icon matChipRemove>cancel</mat-icon>
                          </mat-chip>
                          <input
                            [matChipInputFor]="chipList"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                            [matChipInputAddOnBlur]="true"
                            (matChipInputTokenEnd)="addTags($event, i)"
                          />
                        </mat-chip-list>
                      </mat-form-field>
                    </td>
                    <td>
                      <button
                        mat-raised-button
                        class="btn btn-danger"
                        (click)="saveTagForKey(i)"
                      >
                        SAVE
                      </button>
                    </td>
                    <td>
                      <img
                        src="../../../assets/img/check.svg"
                        alt=""
                        class="info"
                        *ngIf="row.isUpdated"
                        style="height: 20px"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
