import { Component, OnInit } from '@angular/core';

import { LoginAuthService } from '../../services/auth/login-auth.service';
import { Router } from '@angular/router';
import { RequestService } from '../../services/request/request.service';
import { AppStateService } from '../../services';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  username = '';
  usenameErr = '';
  password = '';
  passwordErr = '';
  authEror = '';
  decoded: any;
  token = '';

  constructor(
    private router: Router,
    private auth: RequestService,
    private appStateService: AppStateService
  ) {}

  ngOnInit() {
    if (localStorage.getItem('aqua_auth_token')) {
      this.router.navigate(['/']);
    }
  }

  keyDownFunction(event) {
    if (event.keyCode === 13) {
      this.login();
    }
  }

  login() {
    this.usenameErr = '';
    this.passwordErr = '';
    if (!this.username) {
      this.usenameErr = 'Username is required.';
    }
    if (!this.password) {
      this.passwordErr = 'Password is required.';
    }
    const data = {
      email: this.username,
      password: this.password,
    };
    this.auth.postService('/api/adminlogin', data).subscribe((res: any) => {
      if (res.status === true) {
        localStorage.setItem('access-token', res.data.token);
        this.appStateService.userToken$.next(res.data.token);
        localStorage.setItem('aqua_userid', res.data.user_id);
        localStorage.setItem('aqua_access', res.data.access);
        localStorage.setItem('aqua_auth_token', res.data.token);
        this.router.navigate(['/dashboard']);
      } else {
        this.authEror = res.message;
      }
    });
  }
}
