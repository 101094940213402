<div class="main-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="alert alert-green" *ngIf="success_msg != ''">
          <span>{{ success_msg }}</span>
        </div>
        <div class="card">
          <div class="card-header card-header-danger">
            <h3 class="card-title">Pending Orders List</h3>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table">
                <thead class="text-primary">
                  <tr>
                    <th>OrderId</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Currency</th>
                    <th>Shipping</th>
                    <th>Subtotal</th>
                    <th>Total</th>
                    <th>AdjustedShippingFee</th>
                    <th>CouponCode</th>
                    <th>CouponDiscount</th>
                    <th>DeliveryZoneDiscount</th>
                    <th>MinCartValDeliveryDiscount</th>
                    <th>TotalDiscount</th>
                    <th>UpdatedAt</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of list">
                    <td *ngFor="let data of objectValues(row)">
                      {{ data }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="toast" role="alert" aria-live="assertive" aria-atomic="true">
  <div class="toast-header">
    <strong class="mr-auto">Success</strong>
  </div>
</div>
