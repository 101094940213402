import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import {
  ICategoryList,
  ICategoryListEntity,
  IProduct,
  IProductListApiResponse,
} from '../../models';
import { ProductService } from '../../services/product/product.service';
import { RequestService } from '../../services/request/request.service';

@Component({
  selector: 'app-product-table',
  templateUrl: './product-table.component.html',
  styleUrls: ['./product-table.component.css'],
})
export class ProductTableComponent implements OnInit, AfterViewInit {
  access = localStorage.getItem('aqua_access');
  user_id = localStorage.getItem('aqua_userid');
  productList = [];
  productListByCategoryId: ICategoryListEntity[] = [];
  token = '';
  success_msg = '';
  error_msg = '';
  app_url = environment.backendURL;
  searchText = '';
  highlightedRow: number; // It will hold id of selected row
  dataSource = new MatTableDataSource<IProductListApiResponse>(
    this.productList
  );
  pageIndex: string | number = 0;
  pageSize: string | number = 15;
  orderField = '';
  order = '';
  category_id: string | number;
  total: number;
  totalProducts = 0;
  merchantId = 0;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  totalProductsAllCategories: number | string;
  @ViewChild('input', { static: false }) searchWords: ElementRef;
  merchantList = [];
  displayedColumns: string[] = [
    'id',
    'image',
    'name',
    'mrp',
    'sellPrice',
    'quantity',
    'deliveryCharges',
    'subcategory',
    'productExpiry',
    'merchant',
    'action',
    'status',
  ];

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  constructor(
    private router: Router,
    private auth: RequestService,
    private cdr: ChangeDetectorRef,
    private productService: ProductService
  ) {
    const navigation = this.router.getCurrentNavigation();
    const state = navigation.extras.state as {
      success_msg: string;
      error_msg: string;
    };

    if (!state) {
      return;
    }
    if (state.success_msg) {
      this.success_msg = state.success_msg;
      setTimeout(() => {
        this.success_msg = '';
      }, 3000);
    } else if (state.error_msg) {
      this.error_msg = state.error_msg;
      setTimeout(() => {
        this.error_msg = '';
      }, 3000);
    }
  }

  ngOnInit(): void {
    this.token = localStorage.getItem('aqua_auth_token');
    this.fetchProducts();
    this.fetchItemCountForCategories();
    this.dataSource.sort = this.sort;
    if (this.access === 'admin') {
      this.fetchVendors();
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  fetchVendors() {
    this.auth
      .getService('/api/admin/productList/fetchVendors', this.token)
      .subscribe((res: any) => {
        res.data.vendors?.forEach((value) => {
          this.merchantList.push({
            quantity: value.no_of_products,
            vendor_id: value.vendor_id,
            name: value.name,
          });
        });
        this.totalProducts = res.data.count;
      });
  }

  fetchItemCountForCategories() {
    this.auth
      // tslint:disable-next-line: max-line-length
      .getService(
        this.access === 'admin'
          ? `/api/admin/count_category_list?vendorId=${this.merchantId}`
          : `/api/vendor/vendor_category_list?id=${this.user_id}`,
        this.token
      )
      .subscribe((res: ICategoryList) => {
        this.totalProducts = res.data.count;
        this.totalProductsAllCategories = res.data.count;
        this.productListByCategoryId = [];
        res.data.list?.forEach((value) => {
          this.productListByCategoryId.push({
            id: value.id,
            category_name: value.category_name,
            quantity: value.quantity,
          });
        });
      });
  }

  fetchProducts(options?: { expired: boolean }) {
    const { expired } = options || {};
    const data = {
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
      orderField: expired ? 'expires_at' : this.orderField,
      order: expired ? 'asc' : this.order,
      search: this.searchText,
      category: this.category_id,
      merchant: this.merchantId,
      expired,
    };
    const api = `/api/admin/product_list`;
    this.auth
      .postAuthService(api, data, this.token)
      .subscribe((res: IProductListApiResponse) => {
        if (res.result_code !== 1) {
          return;
        }

        if (!res.data.list?.length) {
          this.productList = [];
          this.total = res.data.count;
          this.dataSource = new MatTableDataSource<IProductListApiResponse>(
            this.productList
          );
          this.cdr.detectChanges();
          return;
        }
        this.productList = res.data.list
          .filter((i) => !!i)
          .map((d) => ({ ...d, status: d.status?.trim() }));
        this.success_msg = '';
        this.total = res.data.count;
        this.dataSource = new MatTableDataSource<IProductListApiResponse>(
          this.productList
        );
        this.cdr.detectChanges();
      });
  }
  onCategorySelection(id: any) {
    if (!id) {
      return;
    }
    this.category_id = id ? id : '';
    this.pageIndex = 0;
    this.searchWords.nativeElement.value = '';
    this.searchText = '';
    if (id === 'expired') {
      this.fetchProducts({ expired: true });
    } else {
      this.fetchProducts();
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.searchText = filterValue.trim().toLowerCase();
    this.pageIndex = 0;
    this.fetchProducts();
  }

  // this func highlights a row
  highlight(id: number) {
    this.highlightedRow = id;
  }
  // this function deletes a row
  delete(id: number) {
    const product_id = id;
    const user_id = localStorage.getItem('aqua_userid');
    if (confirm('Are you sure ?')) {
      this.auth
        .deleteAuthService(
          `/api/vendor/vendor_product_list?vendor_id=${user_id}&prod_id=${product_id}`,
          this.token
        )
        .subscribe((res: any) => {
          if (res.result_code) {
            this.success_msg = 'Deleted successfully';
            this.productList.splice(
              this.productList.findIndex((val) => val.id === product_id),
              1
            );
            this.dataSource = new MatTableDataSource<IProductListApiResponse>(
              this.productList
            );
            this.success_msg = 'Deleted Successfully';
            this.cdr.detectChanges();
            setTimeout(() => {
              this.success_msg = '';
            }, 7000);
          }
        });
    }
  }
  resetProductExpiry(product: IProduct) {
    if (!confirm('This action will restart the expiry timer')) {
      return;
    }

    const newExpiry = this.productService.resetProductExpiry({
      expiresAt: product.expires_at,
      expiryUpdatedAt: product.expiry_updated_at,
    });

    this.auth
      .putAuthService(
        `/api/admin/product-expiry/reset?productId=${
          product.id
        }&newExpiry=${newExpiry.toISOString()}`,
        this.token
      )
      .subscribe((res: any) => {
        if (res.result_code) {
          this.success_msg = res.message;
          product.expires_at = newExpiry.toISOString();
          product.status = 'active';
        } else {
          this.error_msg = res.message;
        }

        setTimeout(() => {
          this.success_msg = '';
          this.error_msg = '';
        }, 4000);
      });
  }

  sortData(sort: Sort) {
    // API call goes here
    this.orderField = sort.active;
    this.order = sort.direction;
    this.success_msg = 'Loading...';
    this.fetchProducts();
  }
  onPageChange(event: any) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.fetchProducts();
  }
  onMerchantSelection(id: number) {
    if (typeof id === 'string') {
      id = parseInt(id, 10);
    }
    this.merchantId = id;
    this.pageIndex = 0;
    this.category_id = 'all';
    this.fetchItemCountForCategories();
    this.fetchProducts();
    this.cdr.detectChanges();
  }

  isProductExpired(expiryDate) {
    return new Date(expiryDate) < new Date();
  }
}
