import { Injectable } from '@angular/core';
import { NavigationEnd } from '@angular/router';
import { format, parseISO } from 'date-fns';
import { BehaviorSubject } from 'rxjs';
import { useSubject } from '../../utils';
@Injectable({
  providedIn: 'root',
})
export class AppStateService {
  windowSize$ = new BehaviorSubject<{
    innerWidth: number;
    innerHeight: number;
  }>(null);
  deviceType$ = new BehaviorSubject<'desktop' | 'mobile'>(null);
  routeNavigationEnd$ = useSubject<NavigationEnd>(null);
  constructor() {}
  userToken$ = new BehaviorSubject<string>('');
  getFormattedDateFromISO({
    isoDateStr,
    dateFormat,
  }: {
    isoDateStr: string;
    dateFormat: 'yyyy-MM-dd HH:mm a';
  }): string {
    return format(parseISO(isoDateStr), dateFormat);
  }
}
