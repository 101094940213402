import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { first, take } from 'rxjs/operators';
import { AppStateService } from '..';
import { IApiResponse, IApiResponseParsedJSONMessage } from '../../models';

@Injectable({
  providedIn: 'root',
})
export class ApiMessageService {
  public message: string;
  public messageType: string;
  public actionButtonConfig: { text: string; navigateTo: string };
  private alertTimeout: NodeJS.Timeout;
  routeChangeSubscription: Subscription;
  constructor(private appStateService: AppStateService) {}
  manageAlertMessage({
    apiRes,
    showMessageFor = 3000,
  }: {
    apiRes: Partial<IApiResponse>;
    showMessageFor?: number;
  }) {
    if (apiRes.message) {
      this.clearExistingTimeout();
      this.clearExistingSubscription();

      this.messageType = apiRes.result_code === 1 ? 'success' : 'error';
      try {
        const parsedJsonMessage = JSON.parse(
          apiRes.message
        ) as IApiResponseParsedJSONMessage;
        this.message = parsedJsonMessage.text;

        const navigateTo =
          this.appStateService.deviceType$.value === 'desktop'
            ? parsedJsonMessage?.actionButtonConfig?.navigateTo?.largeScreen
            : parsedJsonMessage?.actionButtonConfig?.navigateTo?.smallScreen;

        this.actionButtonConfig = {
          text: parsedJsonMessage?.actionButtonConfig?.text,
          navigateTo,
        };
      } catch (error) {
        // it means that message is a plain string
        this.message = apiRes.message;
      }
      const currentUrl = this.appStateService.routeNavigationEnd$.value?.url;
      this.routeChangeSubscription = this.appStateService.routeNavigationEnd$
        .pipe(first((d) => d?.url !== currentUrl))
        .subscribe((_) => {
          this.clearExistingTimeout();
          this.initializeData();
        });

      this.alertTimeout = setTimeout(() => {
        this.initializeData();
        this.clearExistingSubscription();
      }, showMessageFor);
    }
  }

  initializeData() {
    this.message = '';
    this.messageType = '';
    this.actionButtonConfig = null;
  }

  clearExistingTimeout() {
    if (this.alertTimeout) {
      clearTimeout(this.alertTimeout);
    }
  }

  clearExistingSubscription() {
    if (this.routeChangeSubscription) {
      this.routeChangeSubscription.unsubscribe();
    }
  }
}
