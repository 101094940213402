import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { shortPath } from '../app-routes.const';
import { AppStateService } from '../services';
import { RequestService } from '../services/request/request.service';
interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}
export const ROUTES: RouteInfo[] = [
  {
    path: '/dashboard',
    title: 'Dashboard',
    icon: 'dashboard',
    class: '',
  },
  {
    path: '/vendor_input/0',
    title: 'Add Vendor',
    icon: 'person',
    class: '',
  },
  {
    path: '/vendors',
    title: 'Vendor List',
    icon: 'supervised_user_circle',
    class: '',
  },
  {
    path: '/user_list',
    title: 'User List',
    icon: 'supervised_user_circle',
    class: '',
  },
  {
    path: '/category_input/0',
    title: 'Add Category',
    icon: 'input',
    class: '',
  },
  {
    path: '/categories',
    title: 'Category List',
    icon: 'list',
    class: '',
  },
  {
    path: '/product_input/0',
    title: 'Add Live Stock',
    icon: 'input',
    class: '',
  },
  {
    path: '/product_tank/0',
    title: 'Add Tank',
    icon: 'input',
    class: '',
  },
  {
    path: '/product_other/0',
    title: 'Add Other Product',
    icon: 'input',
    class: '',
  },
  {
    path: '/products',
    title: 'Product List',
    icon: 'list',
    class: '',
  },
  {
    path: '/orders',
    title: 'Orders List',
    icon: 'shop',
    class: '',
  },

  {
    path: '/pending-order-list',
    title: 'Pending Orders List',
    icon: 'shop',
    class: '',
  },
  {
    path: '/recommendations',
    title: 'Recommendation List',
    icon: 'list',
    class: '',
  },
  {
    path: '/admin/merchantOrders',
    title: 'Merchant Order List',
    icon: 'shop_two',
    class: '',
  },
  {
    path: '/campaign',
    title: 'Campaign',
    icon: 'flag',
    class: '',
  },
  {
    path: '/offer',
    title: 'Offer List',
    icon: 'supervised_user_circle',
    class: '',
  },
  {
    path: '/auction',
    title: 'Auction',
    icon: 'groups',
    class: '',
  },
  {
    path: `/${shortPath.merchantDeliveryLogic}`,
    title: 'Manage Delivery Text',
    icon: 'emoji_transportation',
    class: '',
  },
];

@Component({
  selector: 'main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.css'],
})
export class MainNavComponent implements OnDestroy, OnInit {
  access: string = localStorage.getItem('aqua_access');
  token = '';
  menuItems: any[];
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );
  tokenSubs: Subscription;
  constructor(
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private auth: RequestService,
    private appStateService: AppStateService,
    private cdr: ChangeDetectorRef
  ) {
    this.menuItems = ROUTES;
  }

  ngOnInit() {
    this.tokenSubs = this.appStateService.userToken$.subscribe((val) => {
      this.token = val;
      // this.checkRoutes();
      this.cdr.detectChanges();
    });
  }

  ngOnDestroy() {
    this.tokenSubs.unsubscribe();
  }

  logout() {
    this.token = localStorage.getItem('aqua_auth_token');
    if (this.token === null) {
      this.router.navigate(['login']);
    }

    console.log('logout call..');
    this.auth
      .deleteAuthService('/api/users/logout', this.token)
      .subscribe((res: any) => {
        if (res.result_code === '1') {
          localStorage.clear();
          this.router.navigate(['login']);
          this.appStateService.userToken$.next('');
        } else {
          localStorage.clear();
          this.router.navigate(['login']);
          this.appStateService.userToken$.next('');
        }
      });
  }
}
