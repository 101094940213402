import {
  FishyhubDatetimePickerComponent,
  FishyhubDatetimePickerRendererComponent,
} from '../../components/fishyhub-datetime-picker/fishyhub-datetime-picker.component';

export const offerTableColumns = {
  offerId: {
    title: 'Offer Id',
    filter: false,
    editable: false,
  },
  offerCode: {
    title: 'Offer Code',
    filter: false,
  },
  offerCodeType: {
    title: 'Offer Code Type',
    filter: false,
    editor: {
      type: 'list',
      config: {
        list: [
          {
            title: 'Voucher',
            value: 'voucher',
          },
          {
            title: 'Promo',
            value: 'promo',
          },
        ],
      },
    },
  },
  expiryDate: {
    title: 'Expiry Date',
    type: 'custom',
    filter: false,
    renderComponent: FishyhubDatetimePickerRendererComponent,
    editor: {
      type: 'custom',
      component: FishyhubDatetimePickerComponent,
    },
  },
  discountType: {
    title: 'Discount Type',
    filter: false,
    editor: {
      type: 'list',
      config: {
        list: [
          {
            title: 'Amount',
            value: 'amount',
          },
          {
            title: 'Percentage',
            value: 'percentage',
          },
        ],
      },
    },
  },
  minCartSubtotal: {
    title: 'Min Cart Subtotal',
    filter: false,
  },
  percentageAmount: {
    title: 'Percentage or Amount',
    filter: false,
  },
  status: {
    title: 'Status',
    filter: false,
    editor: {
      type: 'list',
      config: {
        list: [
          {
            title: 'Active',
            value: 'active',
          },
          {
            title: 'Inactive',
            value: 'inactive',
          },
        ],
      },
    },
  },
  offerCodeDiscountOn: {
    title: 'Offer Code Discount On',
    filter: false,
    editor: {
      type: 'list',
      config: {
        list: [
          {
            title: 'Delivery',
            value: 'delivery',
          },
          {
            title: 'Sub Total',
            value: 'subtotal',
          },
        ],
      },
    },
  },
  startDate: {
    title: 'Starting Date',
    type: 'custom',
    filter: false,
    renderComponent: FishyhubDatetimePickerRendererComponent,
    editor: {
      type: 'custom',
      component: FishyhubDatetimePickerComponent,
    },
  },
};
