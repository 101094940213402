import { AgmCoreModule } from '@agm/core';
import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { GoogleChartsModule } from 'angular-google-charts';
import { NgxPaginationModule } from 'ngx-pagination';

import { AddonInputComponent } from './addon/addon-input/addon-input.component';
import { AddonListComponent } from './addon/addon-list/addon-list.component';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { LoginComponent } from './auth/login/login.component';
import { CategoryInputComponent } from './category/category-input/category-input.component';
import { CategoryListComponent } from './category/category-list/category-list.component';
import { ReusableComponentsModule } from './components/reusable-components.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { IconsComponent } from './icons/icons.component';
import { httpInterceptorProviders } from './interceptors';
import { MainNavComponent } from './main-nav/main-nav.component';
import { MaterialModule } from './material.module';
import { MerchantOrdersComponent } from './merchant-orders/merchant-orders.component';
import { CustomFirebaseModule } from './modules/custom-firebase/custom-firebase.module';
import { NotificationsComponent } from './notifications/notifications.component';
import { OrderDetailsComponent } from './orders/order-details/order-details.component';
import { OrderListComponent } from './orders/order-list/order-list.component';
import { PendingOrderListPageComponent } from './orders/pending-order-list-page/pending-order-list-page.component';
import { OfferListPageComponent } from './pages/offer-list-page/offer-list-page.component';
import { ProductAddonsComponent } from './product/product-addons/product-addons.component';
import { ProductInputOtherComponent } from './product/product-input-other/product-input-other.component';
import { ProductInputTankComponent } from './product/product-input-tank/product-input-tank.component';
import { ProductInputComponent } from './product/product-input/product-input.component';
import { ProductTableComponent } from './product/product-table/product-table.component';
import { RecommendationListComponent } from './recommendation/recommendation-list/recommendation-list.component';
import { LayoutChangeObserverService } from './services/layout-change-observer/layout-change-observer.service';
import { TypographyComponent } from './typography/typography.component';
import { UserDetailsComponent } from './user-details/user-details.component';
import { UserListComponent } from './user-list/user-list.component';
import { VendorDetailComponent } from './vendor/vendor-detail/vendor-detail.component';
import { VendorListComponent } from './vendor/vendor-list/vendor-list.component';

@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    HttpClientModule,
    RouterModule,
    AngularEditorModule,
    MatProgressSpinnerModule,
    MaterialModule,
    AgmCoreModule.forRoot({ apiKey: 'YOUR_GOOGLE_MAPS_API_KEY' }),
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatListModule,
    GoogleChartsModule,
    FlexLayoutModule,
    AppRoutingModule,
    CustomFirebaseModule,
    ReusableComponentsModule,
    NgxPaginationModule,
  ],
  declarations: [
    AppComponent,
    LoginComponent,
    MainNavComponent,
    DashboardComponent,
    TypographyComponent,
    IconsComponent,
    NotificationsComponent,
    VendorListComponent,
    VendorDetailComponent,
    UserListComponent,
    CategoryListComponent,
    CategoryInputComponent,
    ProductInputComponent,
    ProductAddonsComponent,
    ProductInputOtherComponent,
    ProductInputTankComponent,
    AddonListComponent,
    AddonInputComponent,
    OrderListComponent,
    OrderDetailsComponent,
    ProductTableComponent,
    RecommendationListComponent,
    MerchantOrdersComponent,
    UserDetailsComponent,
    PendingOrderListPageComponent,
    OfferListPageComponent,
  ],
  providers: [httpInterceptorProviders, LayoutChangeObserverService],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    private layoutChangeObserverService: LayoutChangeObserverService
  ) {
    this.layoutChangeObserverService.init();
  }
}
