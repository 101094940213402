import { Injectable } from "@angular/core";

declare let $: any;
@Injectable({
  providedIn: "root",
})
export class NotificationService {
  constructor() {}

  show({
    message,
    type,
    duration,
  }: {
    message: string;
    type?: "info" | "danger";
    duration?: number;
  }) {
    $.notify(
      {
        // options
        message,
      },
      {
        // settings
        type: type || "info",
        allow_dismiss: true,
        newest_on_top: false,
        showProgressbar: false,
        placement: {
          from: "top",
          align: "right",
        },
        offset: 20,
        spacing: 10,
        z_index: 1031,
        delay: duration || 2000,
      }
    );
  }
}
