<div class="main-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="alert alert-green" *ngIf="success_msg != ''">
          <span>{{ success_msg }}</span>
        </div>
        <div class="card">
          <div class="card-header bg-danger text-white">
            Orders List
          </div>
          
          <div class="card-body">
            <div>
              <form
                [formGroup]="filterForm"
                (ngSubmit)="loadOrders()"
                class="form-inline"
              >
                <div class="form-group">
                  <label class="mr-2">Search for:</label>
                  <select class="form-control" formControlName="filterType">
                    <option *ngFor="let option of filterOptions" [value]="option.value">{{ option.label }}</option>
                  </select>
                </div>
                <div class="form-group mx-sm-3">
                  <input
                    *ngIf="filterForm.get('filterType').value !== 'orderStatus'"
                    type="text"
                    class="form-control"
                    formControlName="searchTerm"
                  />
                  <select
                    *ngIf="filterForm.get('filterType').value === 'orderStatus'"
                    class="form-control"
                    formControlName="searchTerm"
                  >
                    <option value="">All</option>
                    <option
                      *ngFor="let status of orderStatusOptions"
                      [value]="status"
                    >
                      {{ status }}
                    </option>
                  </select>
                </div>
                <div class="form-group">
                  <button type="submit" class="btn btn-primary mr-2">
                    Search
                  </button>
                  <button
                    type="button"
                    class="btn btn-secondary"
                    (click)="resetFilters()"
                  >
                    Reset
                  </button>
                </div>
              </form>
            </div>

            <div class="table-responsive">
              <table class="table">
                <thead class="text-primary">
                  <tr>
                    <th>ID</th>
                    <th>Customer Name</th>
                    <th>Currency</th>
                    <th>Sub Total</th>
                    <th>Coupon Discount</th>
                    <th>Delivery Discount</th>
                    <th>Shipping Total</th>
                    <th>Total</th>
                    <th>Order Date</th>
                    <th>Payment Status</th>
                    <th>Order Status</th>
                    <th>Order Status Remarks</th>
                    <th>Remarks</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="
                      let row of list
                        | paginate
                          : {
                              itemsPerPage: itemsPerPage,
                              currentPage: currentPage,
                              totalItems: totalRecords
                            }
                    "
                  >
                    <td>
                      <a
                        class="d-flex"
                        [routerLink]="['/order_details/' + row.id]"
                      >
                        <span
                          _ngcontent-gxv-c19=""
                          class="material-icons icon-image-preview"
                          >visibility</span
                        >
                        {{ row.id }}
                      </a>
                    </td>
                    <td class="text-capitalize">{{ row.customer_name }}</td>
                    <td>{{ row.currency }}</td>
                    <td>{{ row.sub_total | number : '1.2-2' }}</td>
                    <td>
                      {{
                        row?.offerCodeDetails?.offerDiscountAmount || 0
                          | number : '1.2-2'
                      }}
                    </td>
                    <td>
                      {{
                        row?.deliveryDiscountInfo?.deliveryDiscountAmount || 0
                          | number : '1.2-2'
                      }}
                    </td>
                    <td>{{ row.shipping_total | number : '1.2-2' }}</td>
                    <td>{{ row.total | number : '1.2-2' }}</td>
                    <td>{{ row.created_on }}</td>
                    <td
                      class="text-capitalize payment_status-{{
                        row.payment_status
                      }}"
                    >
                      {{ row.payment_status }}
                    </td>
                    <td
                      class="text-capitalize whole_order_status-{{
                        row.whole_order_status
                      }}"
                      *ngIf="row.whole_order_status != 'in_progress'"
                    >
                      {{ row.whole_order_status }}
                    </td>
                    <td
                      class="text-capitalize whole_order_status-{{
                        row.whole_order_status
                      }}"
                      *ngIf="row.whole_order_status == 'in_progress'"
                    >
                      In Progress
                    </td>

                    <td>
                      <input #orderRemarks [value]="row.order_remarks" />
                      <button
                        (click)="updateOrderRemarks(row.id, orderRemarks.value)"
                      >
                        <i class="material-icons">done</i>
                      </button>
                    </td>

                    <td class="d-flex">
                      <input #remarks [value]="row.remarks" />
                      <button (click)="updateRemarks(row, remarks.value)">
                        <i class="material-icons">done</i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <!-- Add the pagination component at the bottom of the table -->
              <pagination-controls
                (pageChange)="onPageChange($event)"
              ></pagination-controls>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="toast" role="alert" aria-live="assertive" aria-atomic="true">
  <div class="toast-header">
    <strong class="mr-auto">Success</strong>
  </div>
</div>
