<div class="main-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-8 m-auto">
        <div class="card">
          <div class="card-header card-header-danger">
            <h4 class="card-title">Vendor Input</h4>
          </div>
          <div class="card-body">
            <section *ngIf="vendorId" class="profile-section1">
              <div class="container">
                <div class="row">
                  <div class="col-6 col-sm-6">
                    <div class="profile-box-img">
                      <div class="profile-img-parent">
                        <img
                          width="200"
                          height="200"
                          src="{{ profilePic }}"
                          class="should_lazyload upload-profile-img"
                        />
                      </div>
                      <div class=""></div>
                    </div>
                  </div>
                  <button
                    [matMenuTriggerFor]="menu"
                    class="btn btn-primary upload-img-icon-button"
                  >
                    Edit
                  </button>
                  <mat-menu
                    class="menu-upload-delete"
                    #menu="matMenu"
                    xPosition="before"
                  >
                    <button mat-menu-item (click)="fileUpload.click()">
                      Change
                    </button>
                    <button
                      *ngIf="profilePic != defaultProfilePic"
                      mat-menu-item
                      (click)="deleteProfilePicture()"
                    >
                      Delete
                    </button>
                  </mat-menu>

                  <input
                    hidden="true"
                    type="file"
                    #fileUpload
                    id="fileUpload"
                    name="fileUpload"
                    accept="image/*"
                    (change)="uploadProfilePicture($event.target.files)"
                  />
                  <small>{{ uploadImageMessage }}</small>
                </div>
              </div>
            </section>
            <ng-container *ngIf="inputForm">
              <form [formGroup]="inputForm" (ngSubmit)="input()">
                <div class="row">
                  <div class="col-md-12">
                    <mat-form-field class="example-full-width">
                      <input
                        matInput
                        placeholder="First Name"
                        formControlName="first_name"
                        required
                      />
                    </mat-form-field>
                    <mat-hint
                      *ngIf="isSubmitted && inputFormControls.first_name.errors"
                    >
                      <span
                        class="text-danger"
                        *ngIf="inputFormControls.first_name.errors.required"
                        ><img
                          src="../../../assets/images/info-icon-red.svg"
                          alt=""
                          class="info"
                        />This field is required.</span
                      >
                    </mat-hint>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <mat-form-field class="example-full-width">
                      <input
                        matInput
                        placeholder="Last Name"
                        formControlName="last_name"
                      />
                    </mat-form-field>
                    <mat-hint
                      *ngIf="isSubmitted && inputFormControls.last_name.errors"
                    >
                      <span
                        class="text-danger"
                        *ngIf="inputFormControls.last_name.errors.required"
                        ><img
                          src="../../../assets/images/info-icon-red.svg"
                          alt=""
                          class="info"
                        />This field is required.</span
                      >
                    </mat-hint>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <mat-form-field class="example-full-width">
                      <input
                        matInput
                        placeholder="Email"
                        formControlName="email"
                        required
                      />
                    </mat-form-field>
                    <mat-hint
                      *ngIf="isSubmitted && inputFormControls.email.errors"
                    >
                      <span
                        class="text-danger"
                        *ngIf="inputFormControls.email.errors.required"
                        ><img
                          src="../../../assets/images/info-icon-red.svg"
                          alt=""
                          class="info"
                        />This field is required.</span
                      >
                      <span
                        class="text-danger"
                        *ngIf="inputFormControls.email.errors.pattern"
                        ><img
                          src="../../../assets/images/info-icon-red.svg"
                          alt=""
                          class="info"
                        />Email address invalid.</span
                      >
                    </mat-hint>
                    <mat-hint *ngIf="email_msg">
                      <span class="text-danger" *ngIf="email_msg"
                        ><img
                          src="../../../assets/images/info-icon-red.svg"
                          alt=""
                          class="info"
                        />{{ email_msg }}</span
                      >
                    </mat-hint>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <mat-form-field class="example-full-width">
                      <input
                        matInput
                        placeholder="Mobile"
                        formControlName="mobile"
                        required
                      />
                    </mat-form-field>
                    <mat-hint
                      *ngIf="isSubmitted && inputFormControls.mobile.errors"
                    >
                      <span
                        class="text-danger"
                        *ngIf="inputFormControls.mobile.errors.required"
                        ><img
                          src="../../../assets/images/info-icon-red.svg"
                          alt=""
                          class="info"
                        />This field is required.</span
                      >
                      <span
                        class="text-danger"
                        *ngIf="
                          inputFormControls.mobile.errors.minlength ||
                          inputFormControls.mobile.errors.pattern
                        "
                        ><img
                          src="../../../assets/images/info-icon-red.svg"
                          alt=""
                          class="info"
                        />Phone Number must be numbers & minimum 8 digit.</span
                      >
                    </mat-hint>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <mat-form-field class="example-full-width">
                      <input
                        matInput
                        placeholder="Address"
                        formControlName="street"
                        required
                      />
                    </mat-form-field>
                    <mat-hint
                      *ngIf="isSubmitted && inputFormControls.street.errors"
                    >
                      <span
                        class="text-danger"
                        *ngIf="inputFormControls.street.errors.required"
                        ><img
                          src="../../../assets/images/info-icon-red.svg"
                          alt=""
                          class="info"
                        />This field is required.</span
                      >
                    </mat-hint>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <mat-form-field class="example-full-width">
                      <input
                        matInput
                        placeholder="City"
                        formControlName="city"
                        required
                      />
                    </mat-form-field>
                    <mat-hint
                      *ngIf="isSubmitted && inputFormControls.city.errors"
                    >
                      <span
                        class="text-danger"
                        *ngIf="inputFormControls.city.errors.required"
                        ><img
                          src="../../../assets/images/info-icon-red.svg"
                          alt=""
                          class="info"
                        />This field is required.</span
                      >
                    </mat-hint>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <mat-form-field class="example-full-width">
                      <input
                        matInput
                        placeholder="State"
                        formControlName="state"
                        required
                      />
                    </mat-form-field>
                    <mat-hint
                      *ngIf="isSubmitted && inputFormControls.state.errors"
                    >
                      <span
                        class="text-danger"
                        *ngIf="inputFormControls.state.errors.required"
                        ><img
                          src="../../../assets/images/info-icon-red.svg"
                          alt=""
                          class="info"
                        />This field is required.</span
                      >
                    </mat-hint>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <mat-form-field class="w-100">
                      <mat-label>Country</mat-label>
                      <mat-select
                        [(ngModel)]="countryID"
                        [ngModelOptions]="{ standalone: true }"
                        (selectionChange)="slectOption($event.value)"
                      >
                        <mat-option
                          *ngFor="let row of countries"
                          [(value)]="row.id"
                        >
                          {{ row.text }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-hint *ngIf="country_err_msg">
                      <span class="text-danger" *ngIf="country_err_msg"
                        ><img
                          src="../../../assets/images/info-icon-red.svg"
                          alt=""
                          class="info"
                        />{{ country_err_msg }}</span
                      >
                    </mat-hint>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <mat-form-field class="w-100">
                      <input
                        matInput
                        placeholder="Postal code"
                        formControlName="postalCode"
                        required
                      />
                    </mat-form-field>
                    <mat-hint
                      *ngIf="isSubmitted && inputFormControls.postalCode.errors"
                    >
                      <span
                        class="text-danger"
                        *ngIf="
                          inputFormControls.postalCode.errors.required ||
                          inputFormControls.postalCode.errors.pattern
                        "
                        ><img
                          src="../../../assets/images/info-icon-red.svg"
                          alt=""
                          class="info"
                        />6 digit postal code is required.</span
                      >
                    </mat-hint>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <mat-form-field class="example-full-width">
                      <input
                        matInput
                        placeholder="Default delivery fee"
                        formControlName="standardDeliveryFee"
                        required
                      />
                    </mat-form-field>
                    <mat-hint *ngIf="isSubmitted && standardDeliveryFee.errors">
                      <span
                        class="text-danger"
                        *ngIf="standardDeliveryFee.errors.required"
                        ><img
                          src="../../../assets/images/info-icon-red.svg"
                          alt=""
                          class="info"
                        />This field is required.</span
                      >
                    </mat-hint>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <mat-form-field class="w-100">
                      <mat-label>Is star seller</mat-label>
                      <mat-select
                        [(ngModel)]="is_star_seller"
                        [ngModelOptions]="{ standalone: true }"
                        (selectionChange)="slect_is_star_seller($event.value)"
                      >
                        <mat-option value="No">No</mat-option>
                        <mat-option value="Yes">Yes</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-md-6">
                    <mat-form-field class="w-100">
                      <mat-label>Status</mat-label>
                      <mat-select
                        [(ngModel)]="status"
                        [ngModelOptions]="{ standalone: true }"
                        (selectionChange)="slectStatus($event.value)"
                      >
                        <mat-option value="active">Active</mat-option>
                        <mat-option value="inactive">Inactive</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <mat-form-field class="example-full-width">
                      <input
                        type="password"
                        matInput
                        placeholder="Password"
                        formControlName="password"
                      />
                    </mat-form-field>
                    <mat-hint *ngIf="password.errors?.invalidPassword">
                      <span class="text-danger"
                        ><img
                          src="../../../assets/images/info-icon-red.svg"
                          alt=""
                          class="info"
                        />Please enter a valid password (minimum 8 chars).</span
                      >
                    </mat-hint>
                  </div>
                </div>

                <span class="text-danger">{{ error }}</span>
                <span class="text-success">{{ success }}</span>
                <button
                  mat-raised-button
                  type="submit"
                  [disabled]="button_disabled"
                  class="btn btn-danger pull-right"
                >
                  SAVE
                </button>
                <div class="clearfix"></div>
              </form>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
