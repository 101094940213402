<div class="main-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-8 m-auto">
        <div class="card">
          <div class="card-header card-header-danger">
            <h4 class="card-title">Product Input(Other)</h4>
          </div>
          <div class="card-body">
            <form [formGroup]="inputForm">
              <div class="row">
                <div class="col-md-12">
                  <mat-form-field class="example-full-width">
                    <input
                      matInput
                      placeholder="Product Name"
                      formControlName="product_name"
                      [(ngModel)]="product_name"
                      required
                    />
                  </mat-form-field>
                  <mat-hint
                    *ngIf="
                      (isSubmitted || inputFrm.product_name.touched) &&
                      inputFrm.product_name.errors
                    "
                  >
                    <span
                      class="text-danger"
                      *ngIf="inputFrm.product_name.errors.required"
                      ><img
                        src="../../../assets/images/info-icon-red.svg"
                        alt=""
                        class="info"
                      />This field is required.</span
                    >
                  </mat-hint>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <mat-form-field class="example-full-width">
                    <input
                      matInput
                      placeholder="$ MRP"
                      formControlName="price"
                      [(ngModel)]="price"
                    />
                  </mat-form-field>
                  <mat-hint *ngIf="(isSubmitted || inputFrm.price.touched) && inputFrm.price.errors">
                    <span
                      class="text-danger"
                      *ngIf="inputFrm.price.errors.pattern"
                      ><img
                        src="../../../assets/images/info-icon-red.svg"
                        alt=""
                        class="info"
                      />MRP must be a numerical value.</span
                    >
                  </mat-hint>
                </div>

                <div class="col-md-4">
                  <mat-form-field class="example-full-width">
                    <input
                      matInput
                      placeholder="$ Sell Price"
                      formControlName="sell_price"
                      [(ngModel)]="sell_price"
                      required
                    />
                  </mat-form-field>
                  <mat-hint *ngIf="(isSubmitted || inputFrm.sell_price.touched) && inputFrm.sell_price.errors">
                    <span
                      class="text-danger"
                      *ngIf="inputFrm.sell_price.errors.required"
                      ><img
                        src="../../../assets/images/info-icon-red.svg"
                        alt=""
                        class="info"
                      />This field is required.</span
                    >
                    <span
                      class="text-danger"
                      *ngIf="inputFrm.sell_price.errors.pattern"
                      ><img
                        src="../../../assets/images/info-icon-red.svg"
                        alt=""
                        class="info"
                      />Sell Price must be a numerical value.</span
                    >
                  </mat-hint>
                  <mat-hint *ngIf="sell_price_err_msg">
                    <span class="text-danger" *ngIf="sell_price_err_msg"
                      ><img
                        src="../../../assets/images/info-icon-red.svg"
                        alt=""
                        class="info"
                      />{{ sell_price_err_msg }}</span
                    >
                  </mat-hint>
                </div>

                <div class="col-md-4">
                  <mat-form-field class="example-full-width">
                    <input
                      matInput
                      placeholder="Search Score"
                      formControlName="searchScore"
                    />
                  </mat-form-field>
                  <mat-hint *ngIf="(isSubmitted || inputFrm.searchScore.touched) && inputFrm.searchScore.errors">
                    <span
                      class="text-danger"
                      *ngIf="inputFrm.searchScore.errors?.pattern"
                      ><img
                        src="../../../assets/images/info-icon-red.svg"
                        alt=""
                        class="info"
                      />Search score must be a numerical value.</span
                    >
                  </mat-hint>
                  <mat-hint *ngIf="(isSubmitted || inputFrm.searchScore.touched) && (inputFrm.searchScore.errors?.min || inputFrm.searchScore.errors?.max)">
                    <span
                      class="text-danger"
                      ><img
                        src="../../../assets/images/info-icon-red.svg"
                        alt=""
                        class="info"
                      />Search score must be between 0 to 100.</span
                    >
                  </mat-hint>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4">
                  <mat-form-field class="example-full-width">
                    <input
                      matInput
                      placeholder="$ Standard Delivery Charges"
                      formControlName="delivery_standard"
                      [(ngModel)]="delivery_standard"
                      required
                    />
                  </mat-form-field>
                  <mat-hint
                    *ngIf="isSubmitted && inputFrm.delivery_standard.errors"
                  >
                    <span
                      class="text-danger"
                      *ngIf="inputFrm.delivery_standard.errors.required"
                      ><img
                        src="../../../assets/images/info-icon-red.svg"
                        alt=""
                        class="info"
                      />This field is required.</span
                    >
                    <span
                      class="text-danger"
                      *ngIf="inputFrm.delivery_standard.errors.pattern"
                      ><img
                        src="../../../assets/images/info-icon-red.svg"
                        alt=""
                        class="info"
                      />Charges must be a numerical value.</span
                    >
                  </mat-hint>
                </div>
                <div class="col-md-4">
                  <mat-form-field class="example-full-width">
                    <input
                      matInput
                      placeholder="$ Express Delivery Charges"
                      formControlName="delivery_express"
                      [(ngModel)]="delivery_express"
                    />
                  </mat-form-field>
                  <mat-hint
                    *ngIf="(isSubmitted || inputFrm.delivery_express.touched) && inputFrm.delivery_express.errors"
                  >
                    <span
                      class="text-danger"
                      *ngIf="inputFrm.delivery_express.errors.pattern"
                      ><img
                        src="../../../assets/images/info-icon-red.svg"
                        alt=""
                        class="info"
                      />Charges must be a numerical value.</span
                    >
                  </mat-hint>
                  <mat-hint
                  *ngIf="(isSubmitted || inputFrm.delivery_express.touched) && inputFrm.delivery_express.errors"
                >
                  <span
                    class="text-danger"
                    *ngIf="inputFrm.delivery_express.errors.min || inputFrm.delivery_express.errors.max"
                    ><img
                      src="../../../assets/images/info-icon-red.svg"
                      alt=""
                      class="info"
                    />Charges must be a numerical value.</span
                  >
                </mat-hint>
                </div>

                <div class="col-md-4">
                  <mat-form-field class="example-full-width">
                    <input
                      matInput
                      placeholder="Quantity"
                      formControlName="qty"
                      [(ngModel)]="qty"
                      required
                    />
                  </mat-form-field>
                  <mat-hint *ngIf="(isSubmitted || inputFrm.qty.touched) && inputFrm.qty.errors">
                    <span
                      class="text-danger"
                      *ngIf="inputFrm.qty.errors.required"
                      ><img
                        src="../../../assets/images/info-icon-red.svg"
                        alt=""
                        class="info"
                      />This field is required.</span
                    >
                    <span
                      class="text-danger"
                      *ngIf="inputFrm.qty.errors.pattern"
                      ><img
                        src="../../../assets/images/info-icon-red.svg"
                        alt=""
                        class="info"
                      />Quantity must be in numbers.</span
                    >
                  </mat-hint>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <angular-editor
                    formControlName="more_description"
                    [placeholder]="'More Description*'"
                    [(ngModel)]="more_description"
                    [config]="editorConfig"
                  ></angular-editor>
                  <mat-hint
                    *ngIf="(isSubmitted || inputFrm.more_description.touched) && inputFrm.more_description.errors"
                  >
                    <span
                      class="text-danger"
                      *ngIf="inputFrm.more_description.errors.required"
                      ><img
                        src="../../../assets/images/info-icon-red.svg"
                        alt=""
                        class="info"
                      />This field is required.</span
                    > </mat-hint
                  ><br />
                </div>
              </div>

              <div class="row">
                <div class="col-md-4">
                  <mat-form-field class="w-100">
                    <mat-label>Country *</mat-label>
                    <mat-select
                      [(ngModel)]="countryID"
                      [ngModelOptions]="{ standalone: true }"
                      (selectionChange)="slectCountry($event.value)"
                    >
                      <mat-option
                        *ngFor="let row of countries"
                        [(value)]="row.id"
                      >
                        {{ row.text }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-hint *ngIf="country_err_msg">
                    <span class="text-danger" *ngIf="country_err_msg"
                      ><img
                        src="../../../assets/images/info-icon-red.svg"
                        alt=""
                        class="info"
                      />{{ country_err_msg }}</span
                    >
                  </mat-hint>
                </div>
                <div class="col-md-4">
                  <mat-form-field class="w-100">
                    <mat-label>Vendor</mat-label>
                    <mat-select
                      [(ngModel)]="vendor_id"
                      [ngModelOptions]="{ standalone: true }"
                      (selectionChange)="slectVendor($event.value)"
                    >
                      <mat-option
                        *ngFor="let row of vendors"
                        [(value)]="row.id"
                      >
                        {{ row.first_name }}&nbsp;{{ row.last_name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-hint *ngIf="vendor_err_msg">
                    <span class="text-danger" *ngIf="vendor_err_msg"
                      ><img
                        src="../../../assets/images/info-icon-red.svg"
                        alt=""
                        class="info"
                      />{{ vendor_err_msg }}</span
                    >
                  </mat-hint>
                </div>
                <div class="col-md-4">
                  <mat-form-field class="w-100">
                    <mat-label>Promoted</mat-label>
                    <select matNativeControl formControlName="isPromoted">
                      <option value="false">false</option>
                      <option value="true">true</option>
                    </select>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <mat-form-field class="w-100">
                    <mat-label>Category *</mat-label>
                    <mat-select
                      [(ngModel)]="category_id"
                      [ngModelOptions]="{ standalone: true }"
                      (selectionChange)="slectCategory($event.value)"
                    >
                      <mat-option
                        *ngFor="let row of categories"
                        [(value)]="row.id"
                      >
                        {{ row.category_name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-hint *ngIf="category_err_msg">
                    <span class="text-danger" *ngIf="category_err_msg"
                      ><img
                        src="../../../assets/images/info-icon-red.svg"
                        alt=""
                        class="info"
                      />{{ category_err_msg }}</span
                    >
                  </mat-hint>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="w-100">
                    <mat-label>Sub-Category *</mat-label>
                    <mat-select
                      [(ngModel)]="subcategory_id"
                      [ngModelOptions]="{ standalone: true }"
                      (selectionChange)="slectSubCategory($event.value)"
                    >
                      <mat-option
                        *ngFor="let row of subcategories"
                        [(value)]="row.id"
                      >
                        {{ row.category_name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-hint *ngIf="subcategory_err_msg">
                    <span class="text-danger" *ngIf="subcategory_err_msg"
                      ><img
                        src="../../../assets/images/info-icon-red.svg"
                        alt=""
                        class="info"
                      />{{ subcategory_err_msg }}</span
                    >
                  </mat-hint>
                </div>
              </div>

              <div class="row">
                <div class="col-md-3">
                  <mat-form-field class="w-100">
                    <mat-label>Is Featured</mat-label>
                    <mat-select
                      [(ngModel)]="is_featured"
                      [ngModelOptions]="{ standalone: true }"
                      (selectionChange)="slect_is_featured($event.value)"
                    >
                      <mat-option value="No">No</mat-option>
                      <mat-option value="Yes">Yes</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-hint *ngIf="waterconditon_err_msg">
                    <span class="text-danger" *ngIf="is_featured_err_msg"
                      ><img
                        src="../../../assets/images/info-icon-red.svg"
                        alt=""
                        class="info"
                      />{{ is_featured_err_msg }}</span
                    >
                  </mat-hint>
                </div>

                <div class="col-md-3">
                  <mat-form-field class="w-100">
                    <mat-label>Is Exclusive</mat-label>
                    <mat-select
                      [(ngModel)]="is_exclusive"
                      [ngModelOptions]="{ standalone: true }"
                      (selectionChange)="slect_is_exclusive($event.value)"
                    >
                      <mat-option value="No">No</mat-option>
                      <mat-option value="Yes">Yes</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="col-md-3">
                  <mat-form-field class="w-100">
                    <mat-label>Available in build aquarium</mat-label>
                    <mat-select
                      [(ngModel)]="available_in_build_aquarium"
                      [ngModelOptions]="{ standalone: true }"
                      (selectionChange)="
                        slect_available_in_build_aquarium($event.value)
                      "
                    >
                      <mat-option value="No">No</mat-option>
                      <mat-option value="Yes">Yes</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="col-md-3">
                  <mat-form-field class="w-100">
                    <mat-label>Status</mat-label>
                    <mat-select
                      [(ngModel)]="status"
                      [ngModelOptions]="{ standalone: true }"
                      (selectionChange)="slectStatus($event.value)"
                    >
                      <mat-option value="active">Active</mat-option>
                      <mat-option value="inactive">Inactive</mat-option>
                      <mat-option value="auction_active">Auction-Active-Item</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <mat-form-field class="example-chip-list">
                    <mat-label>Tags</mat-label>
                    <mat-chip-list #chipList aria-label="Fruit selection">
                      <mat-chip
                        *ngFor="let tag of tagsData"
                        [selectable]="true"
                        [removable]="true"
                        (removed)="removeTags(tag)"
                      >
                        {{ tag }}
                        <mat-icon matChipRemove>cancel</mat-icon>
                      </mat-chip>
                      <input
                        placeholder="Tags..."
                        [matChipInputFor]="chipList"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        [matChipInputAddOnBlur]="true"
                        (matChipInputTokenEnd)="addTags($event)"
                      />
                    </mat-chip-list>
                  </mat-form-field>
                </div>
              </div>

              <div class="row">
                <div class="col-md-3">
                  <div class="upload-img">
                    <img
                      class="img"
                      *ngIf="product_image_url != ''"
                      src="{{ product_image_url }}"
                    />
                  </div>
                </div>
                <div class="col-12 col-md-12">
                  <div class="row">
                    <div class="col-md-3">
                      <button
                        class="btn btn-danger mb-3"
                        (click)="fileInput.click()"
                        type="button"
                      >
                        <span>Featured Image</span>
                        <input
                          #fileInput
                          type="file"
                          (change)="onFileInput($event, $event.target.files)"
                          style="display: none"
                          accept="image/*"
                        />
                      </button>
                    </div>
                    <div class="col-md-9 pt-3">
                      <span class="text-danger" *ngIf="image_err_msg"
                        ><img
                          src="../../../assets/images/info-icon-red.svg"
                          alt=""
                          class="info"
                        />{{ image_err_msg }}</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="row d-flex">
                    <div
                      class="col-md-3 upload-img"
                      *ngFor="let row of other_files_db"
                    >
                      <img class="img" src="{{ row.product_img }}" />
                      <a
                        href="javascript:void(0);"
                        (click)="delImgDB(row.id, 0)"
                        ><img
                          src="../../../assets/img/close-icon.svg"
                          alt=""
                          class="close-icon"
                      /></a>
                    </div>

                    <div
                      class="col-md-3 upload-img"
                      *ngFor="let row of other_files; let i = index"
                    >
                      <img
                        class="img"
                        *ngIf="row.ext.toLowerCase() != 'mp4'"
                        style="height: 100px; width: 100px"
                        src="{{ row.img }}"
                      />
                      <a href="javascript:void(0);" (click)="delImg(i)"
                        ><img
                          src="../../../assets/img/close-icon.svg"
                          alt=""
                          class="close-icon"
                      /></a>
                    </div>

                    <div class="col-12 col-md-12">
                      <div class="row">
                        <div class="col-md-3 text-left">
                          <button
                            class="btn btn-danger mb-3"
                            (click)="multifile.click()"
                            type="button"
                          >
                            <span>Other Image(s)</span>
                            <input
                              #multifile
                              type="file"
                              (change)="multiFiles($event, $event.target.files)"
                              style="display: none"
                              multiple
                              accept="image/*"
                            />
                          </button>
                        </div>
                        <div class="col-md-9 pt-3">
                          <span class="text-danger" *ngIf="other_img_err_msg"
                            ><img
                              src="../../../assets/images/info-icon-red.svg"
                              alt=""
                              class="info"
                            />{{ other_img_err_msg }}</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="row d-flex">
                    <div
                      class="col-md-3 upload-img"
                      *ngFor="let row of other_videos_db"
                    >
                      <video class="img" controls>
                        <source src="{{ row.product_img }}" type="video/mp4" />
                        <source src="{{ row.product_img }}" type="video/ogg" />
                        Your browser does not support the video tag.
                      </video>
                      <a
                        href="javascript:void(0);"
                        (click)="delImgDB(row.id, 1)"
                        ><img
                          src="../../../assets/img/close-icon.svg"
                          alt=""
                          class="close-icon"
                      /></a>
                    </div>

                    <div
                      class="col-md-3 upload-img"
                      *ngFor="let row of other_videos; let i = index"
                    >
                      <video
                        class="img"
                        controls
                      >
                        <source src="{{ row.img }}" type="video/mp4" />
                        <source src="{{ row.img }}" type="video/ogg" />
<source src="{{ row.img }}" type="video/mov" />
                        Your browser does not support the video tag.
                      </video>
                      <a href="javascript:void(0);" (click)="delVdo(i)"
                        ><img
                          src="../../../assets/img/close-icon.svg"
                          alt=""
                          class="close-icon"
                      /></a>
                    </div>

                    <div class="col-12 col-md-12">
                      <div class="row">
                        <div class="col-md-3 text-left">
                          <button
                            class="btn btn-danger mb-3"
                            (click)="multivdo.click()"
                            type="button"
                          >
                            <span>Other Video(s)</span>
                            <input
                              #multivdo
                              type="file"
                              (change)="
                                multiVideos($event, $event.target.files)
                              "
                              style="display: none"
                            />
                          </button>
                        </div>
                        <div class="col-md-9 pt-3">
                          <span class="text-danger" *ngIf="other_vdo_err_msg"
                            ><img
                              src="../../../assets/images/info-icon-red.svg"
                              alt=""
                              class="info"
                            />{{ other_vdo_err_msg }}</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- video close -->
              <span class="text-danger">{{ error }}</span>
              <span class="text-success">{{ success }}</span>

              <div class="clearfix"></div>
              <a href="javascript:void(0);">
                <span>[ No. of option(s) => {{ list?.length }} ]</span></a
              >
            </form>

            <div class="row">
              <div class="col-md-12 m-auto">
                <div class="card">
                  <div class="card-header card-header-danger">
                    <h4 class="card-title">Option Input</h4>
                  </div>
                  <div class="card-body">
                    <form
                      [formGroup]="inputOptionForm"
                      (ngSubmit)="inputOption()"
                    >
                      <div class="row">
                        <div class="col-md-5">
                          <mat-form-field class="example-full-width">
                            <input
                              matInput
                              placeholder="Addon Name"
                              formControlName="addon_name"
                              [(ngModel)]="addon_name"
                              required
                            />
                          </mat-form-field>
                          <mat-hint
                            *ngIf="
                              isSubmittedOption &&
                              inputOptionFrm.addon_name.errors
                            "
                          >
                            <span
                              class="text-danger"
                              *ngIf="inputOptionFrm.addon_name.errors.required"
                              ><img
                                src="../../../assets/images/info-icon-red.svg"
                                alt=""
                                class="info"
                              />This field is required.</span
                            >
                          </mat-hint>
                        </div>
                        <div class="col-md-5">
                          <mat-form-field class="example-full-width">
                            <input
                              matInput
                              placeholder="$ Price"
                              formControlName="addon_price"
                              [(ngModel)]="addon_price"
                              required
                            />
                          </mat-form-field>

                          <mat-hint
                            *ngIf="
                              isSubmittedOption &&
                              inputOptionFrm.addon_price.errors
                            "
                          >
                            <span
                              class="text-danger"
                              *ngIf="inputOptionFrm.addon_price.errors.required"
                              ><img
                                src="../../../assets/images/info-icon-red.svg"
                                alt=""
                                class="info"
                              />This field is required.</span
                            >
                            <span
                              class="text-danger"
                              *ngIf="inputOptionFrm.addon_price.errors.pattern"
                              ><img
                                src="../../../assets/images/info-icon-red.svg"
                                alt=""
                                class="info"
                              />Price must be a numerical value.</span
                            >
                          </mat-hint>
                        </div>
                        <div class="col-md-2">
                          <button
                            mat-raised-button
                            type="submit"
                            [disabled]="button_disabled_option"
                            class="btn btn-danger pull-right"
                          >
                            {{ option_submit_btn }}
                          </button>
                          <div class="clearfix"></div>
                        </div>
                        <span class="text-danger">{{ option_error }}</span>
                        <span class="text-success">{{ option_success }}</span>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-header card-header-danger">
                    <h4 class="card-title">Option List</h4>
                  </div>
                  <div class="card-body">
                    <div class="table-responsive">
                      <table class="table">
                        <thead class="text-primary">
                          <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Price($)</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let row of list">
                            <td>{{ row.id }}</td>
                            <td>{{ row.addon_name }}</td>
                            <td>{{ row.addon_price }}</td>
                            <td>
                              <a
                                href="javascript:void(0);"
                                (click)="optionEdit(row.id)"
                              >
                                <span
                                  _ngcontent-gxv-c19=""
                                  class="material-icons icon-image-preview"
                                  >edit</span
                                >
                              </a>
                              &nbsp;<a
                                href="javascript:void(0);"
                                (click)="optionDel(row.id)"
                              >
                                <span
                                  _ngcontent-gxv-c19=""
                                  class="material-icons icon-image-preview"
                                  >delete</span
                                >
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button
              mat-raised-button
              type="submit"
              (click)="input()"
              [disabled]="button_disabled"
              class="btn btn-danger pull-right"
            >
              {{ form_submit_btn_name }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
