import { RequestService } from "./../../services/request/request.service";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { MatChipInputEvent } from "@angular/material/chips";

@Component({
  selector: "app-recommendation-list",
  templateUrl: "./recommendation-list.component.html",
  styleUrls: ["./recommendation-list.component.scss"],
})
export class RecommendationListComponent implements OnInit {
  list = [];
  token = "";
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(private auth: RequestService, private router: Router) {}

  ngOnInit(): void {
    this.loadTags();
  }

  loadTags() {
    this.token = localStorage.getItem("aqua_auth_token");
    this.auth
      .getService("/api/admin/get_recommendation", this.token)
      .subscribe((res: any) => {
        if (res.result_code == "1") {
          res.data.forEach((element) => {
            this.list.push({
              key: element.key,
              tags: element.tags.split(","),
              isUpdated: false,
            });
          });
          console.log(this.list);
        } else {
          console.log(res);
        }
      });
  }

  removeTags(tag: string, i): void {
    const index = this.list[i].tags.indexOf(tag);

    if (index >= 0) {
      this.list[i].tags.splice(index, 1);
    }
  }
  addTags(event: MatChipInputEvent, i): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || "").trim()) {
      this.list[i].tags.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = "";
    }
  }
  saveTagForKey(i): void {
    this.list[i].isUpdated = true;
    this.token = localStorage.getItem("aqua_auth_token");
    var data = { key: this.list[i].key, tags: this.list[i].tags.join(",") };

    this.auth
      .postService("/api/admin/update_recommendation", data)
      .subscribe((res: any) => {
        console.log(res);
        this.loadTags();
        setTimeout(() => {
          this.list[i].isUpdated = false;
        }, 2000);
      });
  }
}
