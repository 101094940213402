import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';

import { IOrderDetailEntity } from '../../models';
import { AppStateService } from '../../services/app-state/app-state.service';
import { NotificationService } from '../../services/notification/notification.service';
import { RequestService } from '../../services/request/request.service';

@Component({
  selector: 'app-pending-order-list-page',
  templateUrl: './pending-order-list-page.component.html',
  styleUrls: ['./pending-order-list-page.component.scss'],
})
export class PendingOrderListPageComponent implements OnInit {
  list: any;
  token = '';
  cname = '';
  checked = false;
  success_msg = '';
  error_msg = '';
  app_url = environment.backendURL;
  access = localStorage.getItem('aqua_access');
  objectValues = Object.values;

  constructor(
    private router: Router,
    private auth: RequestService,
    private route: ActivatedRoute,
    private appStateService: AppStateService,
    private notificationService: NotificationService
  ) {
    this.cname = this.route.snapshot.queryParamMap.get('cname');
    const navigation = this.router.getCurrentNavigation();
    const state = navigation.extras.state as {
      success_msg: string;
      error_msg: string;
    };
    if (state != undefined) {
      if (state.success_msg) {
        this.success_msg = state.success_msg;
        setTimeout(() => {
          this.success_msg = '';
        }, 3000);
      } else if (state.error_msg) {
        this.error_msg = state.error_msg;
        setTimeout(() => {
          this.error_msg = '';
        }, 3000);
      }
    }
  }

  ngOnInit(): void {
    this.token = localStorage.getItem('aqua_auth_token');
    this.access = localStorage.getItem('aqua_access');
    this.auth
      .getService('/api/admin/pending_order_list', this.token)
      .subscribe((res: any) => {
        if (res.result_code == '1') {
          this.list = (res.data || []).map((o) => ({
            ...o,
            updated_on: this.appStateService.getFormattedDateFromISO({
              dateFormat: 'yyyy-MM-dd HH:mm a',
              isoDateStr: o.updated_on,
            }),
          }));

          // console.log(this.list);
        } else {
          console.log(res);
        }
      });
  }

  updateRemarks(orderDetail: IOrderDetailEntity, remarks: string) {
    if (this.access !== 'admin') {
      return 0;
    }

    const token = localStorage.getItem('aqua_auth_token');

    this.auth
      .postAuthService(
        '/api/admin/update_remarks',
        { orderId: orderDetail.id, remarks },
        token
      )
      .subscribe((res: any) => {
        if (res.status === true) {
          this.notificationService.show({ message: 'Success' });
        }
      });
  }
  // The following func updates order_remarks
  updateOrderRemarks(orderId: string, remarks: string) {
    if (this.access !== 'admin') {
      return 0;
    }
    const token = localStorage.getItem('aqua_auth_token');
    this.auth
      .postAuthService(
        '/api/admin/update_order_remarks',
        {
          orderId: orderId,
          remarks: remarks,
        },
        token
      )
      .subscribe((res: any) => {
        if (res.status === true) {
          this.notificationService.show({ message: 'Success' });
        }
      });
  }
}
