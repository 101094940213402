<div class="main-content">
  <div class="container-fluid">
    
    <div class="row">
		
      <div class="col-md-6 m-auto">
       <div class="alert alert-green" *ngIf="success_msg!=''">
              <span>{{success_msg}}</span>
         </div>
        <div class="card">
          <div class="card-header card-header-danger">
            <h4 class="card-title">Addons Input</h4>
          </div>
          <div class="card-body">
			<form [formGroup]="inputForm" (ngSubmit)="input()">
              <div class="row">
                <div class="col-md-12">
                  <mat-form-field class="example-full-width">
                    <input matInput placeholder="Addon Name" formControlName="addon_name" [(ngModel)]=addon_name required >

                  </mat-form-field>
                  <mat-hint *ngIf="isSubmitted && inputFrm.addon_name.errors">  
                      <span class="text-danger" *ngIf="inputFrm.addon_name.errors.required"><img src="../../../assets/images/info-icon-red.svg" alt="" class="info" />This field is required.</span>
                  </mat-hint>
                </div>
              </div>
              <div class="row">
                  <div class="col-md-12">
                    <mat-form-field class="example-full-width">
                      <input matInput placeholder="$ Price" formControlName="price" [(ngModel)]=price required >
  
                    </mat-form-field>
                    
                    <mat-hint *ngIf="isSubmitted && inputFrm.price.errors">  
                        <span class="text-danger" *ngIf="inputFrm.price.errors.required"><img src="../../../assets/images/info-icon-red.svg" alt="" class="info" />This field is required.</span>
                        <span class="text-danger" *ngIf="inputFrm.price.errors.pattern "><img src="../../../assets/images/info-icon-red.svg" alt="" class="info" />Price must be a numerical value.</span>
                    </mat-hint> 
                  </div>
                </div>
              <span class="text-danger">{{ error }}</span>
              <span class="text-success">{{ success }}</span>
              <button mat-raised-button type="submit" [disabled]="button_disabled" class="btn btn-danger pull-right">SAVE</button>
              <div class="clearfix"></div>
              <br>
              <a [routerLink]="['/product_input/'+pid]" style="margin-left: 0px;">
                  <span _ngcontent-gxv-c19="" class="material-icons icon-image-preview">edit</span>&nbsp;Product
              </a>
              <a [routerLink]="['/product_input/'+pid]" style="margin-left: 70%;">
                  <span _ngcontent-gxv-c19="" class="material-icons icon-image-preview">edit</span>&nbsp;Images
              </a>
             </form> 
          </div>
        </div>
      </div>
   
 
    </div>
    <div class="row">
      <div class="col-md-12">
          
        <div class="card">
          <div class="card-header card-header-danger">
            <h4 class="card-title ">Addons List</h4>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table">
                <thead class=" text-primary">
                  <tr>
                    <th> ID </th>
                    <th> Name </th>
                    <th> Price($) </th>
                    <th> Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of list">
                    <td> {{ row.id }} </td>
                    <td> {{ row.addon_name }} </td>
                    <td> {{ row.addon_price }} </td>
                    <td>
                      <a href="/product_addons/{{row.product_id}}/{{row.id}}" >
                          <span _ngcontent-gxv-c19="" class="material-icons icon-image-preview">edit</span>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
