<article class="user-details">
  <div class="main-content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="alert alert-green" *ngIf="success_msg != ''">
            <span>{{ success_msg }}</span>
          </div>

          <div class="card">
            <div class="card-header card-header-danger">
              <h4 class="card-title">{{ name }}'s Details</h4>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th class="text-primary">Name</th>
                      <th class="text-capitalize">{{ name }}</th>
                    </tr>

                    <tr>
                      <th class="text-primary">Email</th>
                      <th class="text-capitalize">{{ email }}</th>

                      <th class="text-primary">Phone</th>
                      <th class="text-capitalize">{{ phone_no }}</th>
                    </tr>

                    <tr>
                      <th class="text-primary">Address</th>
                      <th class="text-capitalize">{{ address }}</th>

                      <th class="text-primary">Postal Code</th>
                      <th class="text-capitalize">{{ postalCode }}</th>
                    </tr>

                    <tr>
                      <th class="text-primary">Created_on</th>
                      <th class="text-capitalize">
                        {{ created_on | date: "medium" }}
                      </th>

                      <th class="text-primary">Last Access</th>
                      <th class="text-capitalize">
                        {{ lastAccess | date: "medium" }}
                      </th>
                    </tr>

                    <tr>
                      <th class="text-primary">Experience</th>
                      <th class="text-capitalize">
                        {{ quizResult.experienceLevel }}
                      </th>
                      <th class="text-primary">Aquarium Description</th>
                      <th class="text-capitalize">
                        {{ quizResult.aquariumBestDescription }}
                      </th>
                    </tr>
                    <tr>
                      <th class="text-primary">Fish Type</th>
                      <th class="text-capitalize">{{ quizResult.fishType }}</th>
                      <th class="text-primary">Tank Capacity</th>
                      <th class="text-capitalize">
                        {{ quizResult.tankCapacity }}
                      </th>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
          </div>

          <div *ngIf="this.totalItems > 0" class="card">
            <div class="card-header card-header-danger">
              <h4 class="card-title">Price Info</h4>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th class="text-primary">Total</th>
                      <th class="text-capitalize">{{priceInfo.total | number : "1.2-2"}}</th>
                    </tr>
                  </thead>
                  <thead>
                    <tr>
                      <th class="text-primary">Sub Total</th>
                      <th class="text-capitalize">{{priceInfo.subtotal | number : "1.2-2"}}</th>
                    </tr>
                    <th class="text-primary">Shipping</th>
                      <th class="text-capitalize">{{priceInfo.shipping | number : "1.2-2"}}</th>
                  </thead>

                  <thead *ngIf="offerCodeDetails">
                    <tr>
                      <th class="text-primary">Coupon Code Discount (#{{offerCodeDetails.offerCode}})</th>
                      <th class="text-capitalize">{{offerCodeDetails.offerDiscountAmount | number : "1.2-2"}}</th>
                    </tr>
                    <th class="text-primary">Offer Code</th>
                      <th class="text-capitalize">{{priceInfo.offerCode}}</th>
                  </thead>

                </table>
              </div>
            </div>
          </div>

          <div *ngIf="this.totalItems > 0" class="card">
            <div class="card-header card-header-danger">
              <h4 class="card-title">Cart</h4>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table class="table">
                  <thead>
<th>Id</th>
<th>Product Image</th>
<th>Product Name</th>
<th>Addon Name</th>
<th>Currency</th>
<th>Option Price</th>
<th>Shipping</th>
<th>Quantity</th>
<th>Vendor</th>

                  </thead>

                  <tbody>

                    <tr *ngFor="let product of productList">
                      <td>{{product.product_id}}</td>
                      <td><img src={{product.product_image}} alt={{product.product_name}}/></td>
                      <td>{{product.product_name}}</td>
                      <td>{{product.addon_name}}</td>
                      <td>{{product.price_symbol}}</td>
                      <td>{{product.option_price | number : "1.2-2"}}</td>
                      <td>{{product.delivery_price | number : "1.2-2"}}</td>
                      <td>{{product.quantity}}</td>
                      <td>{{product.vendor_name}}</td>
                    </tr>

                  </tbody>
                </table>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</article>
