import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IDashboardApiResponse } from '../models';
import { AppStateService } from '../services/app-state/app-state.service';
import { RequestService } from '../services/request/request.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  dailyOrdersData = [];
  dailyOrdersOptions = this.getChartOptions({
    xAxisTitle: 'Date',
    yAxisTitle: 'Orders',
  });
  dailyOrderTableColumns = this.getColumns(['order_date', '#no of orders']);
  dailySalesData = [];
  dailySalesColumn = this.getColumns(['date', '#sales']);
  dailySalesOptions = this.getChartOptions({
    xAxisTitle: 'Date',
    yAxisTitle: 'Sales',
  });

  monthlyUsersCount = [];
  monthlySalesData = [];
  monthlyOrdersData = [];
  monthlySalesColumn = [
    { type: 'string', label: 'month' },
    { type: 'number', label: '#sales' },
    { type: 'number', role: 'annotation' },
  ];
  dailyUsersCount = [];
  dailyUsersCountColumn = [
    { type: 'string', label: 'date' },
    { type: 'number', label: '#no of users' },
    { type: 'number', role: 'annotation' },
  ];
  dailyUsersCountOptions = {
    hAxis: {
      title: 'Date',
    },
    vAxis: {
      title: 'No of users',
    },
    colors: ['#FF8040'],
    displayAnnotations: true,
  };
  monthlyUsersCountColumn = [
    { type: 'string', label: 'month' },
    { type: 'number', label: '#no of users' },
    { type: 'number', role: 'annotation' },
  ];

  monthlyOrdersColumn = [
    { type: 'string', label: 'month' },
    { type: 'number', label: '#no of orders' },
    { type: 'number', role: 'annotation' },
  ];
  monthlyOrdersOptions = {
    hAxis: {
      title: 'Month',
    },
    vAxis: {
      title: 'Orders',
    },
    colors: ['#0c55b8'],
    displayAnnotations: true,
  };
  monthlyUsersCountOptions = {
    hAxis: {
      title: 'Month',
    },
    vAxis: {
      title: 'No of users',
    },
    colors: ['#FF8040'],
    displayAnnotations: true,
  };
  monthlySalesOptions = this.getChartOptions({
    xAxisTitle: 'Month',
    yAxisTitle: 'Sales',
  });

  access: string = localStorage.getItem('aqua_access');
  token: string = localStorage.getItem('access-token');
  monthlySalesAndOrders = [];
  dailySalesAndOrders = [];
  monthlyActiveUsers = [];

  monthlyActiveUsersChartOption = this.getChartOptions({
    xAxisTitle: 'Month',
    yAxisTitle: '#no of users',
  });
  monthlyActiveUsersColumns = this.getColumns(['month', '#number of users']);

  dailyActiveUsers = [];
  dailyActiveUsersChartOption = this.getChartOptions({
    xAxisTitle: 'Date',
    yAxisTitle: '#no of users',
  });
  dailyActiveUsersColumns = this.getColumns(['date', '#number of users']);
  constructor(
    private request: RequestService,
    public appStateService: AppStateService,
    private router: Router
  ) {}

  ngOnInit() {
    if (!!!localStorage.getItem('aqua_auth_token')) {
      this.router.navigate(['/login']);
    }
    this.request
      .getService(`/api/admin/dashboard`, this.token)
      .subscribe((res: IDashboardApiResponse) => {
        if (res.result_code === 1) {
          this.monthlySalesAndOrders = res.data.monthly;
          this.dailySalesAndOrders = res.data.daily;
          this.dailyUsersCount = res.data.dailyUsersCount.map((thatDate) => {
            return [
              thatDate.joined_on,
              +thatDate.total_daily_users,
              +thatDate.total_daily_users,
            ];
          });
          this.monthlyUsersCount = res.data.monthlyUsersCount.map(
            (thatMonth) => {
              return [
                thatMonth.month,
                parseInt(thatMonth.monthly_daily_users, 10),
                parseInt(thatMonth.monthly_daily_users, 10),
              ];
            }
          );
          this.dailyOrdersData = this.dailySalesAndOrders.map((thatDate) => {
            return [
              thatDate.created_on,
              parseInt(thatDate.orders, 10),
              parseInt(thatDate.orders, 10),
            ];
          });
          this.dailySalesData = this.dailySalesAndOrders.map((thatDate) => {
            return [thatDate.created_on, thatDate.sales, thatDate.sales];
          });
          this.monthlyOrdersData = this.monthlySalesAndOrders.map(
            (thatMonth) => {
              return [
                thatMonth.month,
                parseInt(thatMonth.orders, 10),
                parseInt(thatMonth.orders, 10),
              ];
            }
          );
          this.monthlySalesData = this.monthlySalesAndOrders.map(
            (thatMonth) => {
              return [thatMonth.month, thatMonth.sales, thatMonth.sales];
            }
          );

          this.monthlyActiveUsers = res.data.monthlyActiveUsers.map(
            (thatMonth) => {
              return [
                thatMonth.month,
                parseInt(thatMonth.userCount, 10),
                parseInt(thatMonth.userCount, 10),
              ];
            }
          );
          this.dailyActiveUsers = res.data.dailyActiveUsers.map((thatMonth) => {
            return [
              thatMonth.date,
              parseInt(thatMonth.userCount, 10),
              parseInt(thatMonth.userCount, 10),
            ];
          });
        }
      });
  }

  getChartOptions({ xAxisTitle, yAxisTitle }) {
    return {
      hAxis: {
        title: xAxisTitle,
      },
      vAxis: {
        title: yAxisTitle,
      },
      colors: ['#FF8040'],
      displayAnnotations: true,
    };
  }
  getColumns(arr = []) {
    return [
      { type: 'string', label: arr[0] },
      { type: 'number', label: arr[1] },
      { type: 'number', role: 'annotation' },
    ];
  }
}
