export const notifyWhenKeyIsPresent = ({
  object,
  key,
}: {
  object: any;
  key: string;
}) => {
  const intervalDuration = 400;
  const tryUntil = 300000; // 5 minutes
  let remainingDurationToSearch = tryUntil;
  if (intervalDuration >= tryUntil) {
    return null;
  }

  const result = new Promise<boolean>((resolve) => {
    const interval = setInterval(() => {
      const element = key in object;

      if (!!element) {
        console.log(`found ${key} in object`);
        clearInterval(interval);
        resolve(true);
        return;
      }

      console.log(
        `Searching for ${key} inside 'object'. TimeRemaining to search: ${
          remainingDurationToSearch / 1000
        } seconds`
      );

      remainingDurationToSearch -= intervalDuration;
      if (remainingDurationToSearch < 0) {
        clearInterval(interval);
        resolve(null);
      }
    }, intervalDuration);
  });

  return result;
};
