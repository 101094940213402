<div class="main-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6 m-auto">
        <div class="card">
          <div class="card-header card-header-danger">
            <h4 class="card-title">Category Input</h4>
          </div>
          <div class="card-body">
			<form [formGroup]="inputForm" (ngSubmit)="input()">
              <div class="row">
                <div class="col-md-12">
                  <mat-form-field class="example-full-width">
                    <input matInput placeholder="Category Name" formControlName="category_name" [(ngModel)]=category_name required >

                  </mat-form-field>
                  <mat-hint *ngIf="isSubmitted && inputFrm.category_name.errors">  
                      <span class="text-danger" *ngIf="inputFrm.category_name.errors.required"><img src="../../../assets/images/info-icon-red.svg" alt="" class="info" />This field is required.</span>
                  </mat-hint>
                </div>
              </div>
              
              <div class="row" *ngIf="categoryID !=0 || id ==0">
                  <div class="col-md-12">                 

                       <mat-form-field class=" w-100">
                          <mat-label>Category List</mat-label> 
                           <!-- <label class="input_label"></label> -->
                             <mat-select [(ngModel)]=categoryID [ngModelOptions]="{standalone: true}" (selectionChange)="slectOption($event.value)">
                               <mat-option  *ngFor="let row of cateries" [(value)]="row.id" >
                                  {{row.category_name}}
                              </mat-option >
                          </mat-select>
                      </mat-form-field>    
                  </div>
              </div>     
              <div class="row">
                      
                
              </div>    

              <div class="row">
                  <div class="col-md-6">
                      <mat-form-field class=" w-100">
                        <mat-label>Is Featured</mat-label>
                            <mat-select [(ngModel)]=is_featured [ngModelOptions]="{standalone: true}" (selectionChange)="slect_is_featured($event.value)">
                            <mat-option  value="No" >No</mat-option >
                            <mat-option  value="Yes" >Yes</mat-option >
                        </mat-select>
                    </mat-form-field>
                    <mat-hint *ngIf="is_featured_err_msg">  
                        <span class="text-danger" *ngIf="is_featured_err_msg"><img src="../../../assets/images/info-icon-red.svg" alt="" class="info" />{{is_featured_err_msg}}</span>
                    </mat-hint>    
                </div> 
                <div class="col-md-6">
                  <div class="row" >
                    <div class="col-md-12 upload-img">
                      <img  class="img" *ngIf="category_img_url !='' " src="{{ category_img_url }}"  />
                    </div>
                    <div class="col-md-12">
                      <button class="btn btn-danger" (click)="fileInput.click()" type="button">
                        <span>Upload Image</span>
                        <input #fileInput type="file" (change)="onFileInput($event,$event.target.files)" style="display:none;" accept="image/*" />
                      </button>
                      <br><span class="text-danger" *ngIf="image_err_msg" ><img src="../../../assets/images/info-icon-red.svg" alt="" class="info" />{{image_err_msg}}</span>
                    </div>
                  </div>
                </div>
              </div>  
              <span class="text-danger">{{ error }}</span>
              <span class="text-success">{{ success }}</span>
              <button mat-raised-button type="submit" [disabled]="button_disabled" class="btn btn-danger pull-right">SAVE</button>
              <div class="clearfix"></div>
             </form> 
          </div>
        </div>
      </div>
   
 
    </div>
  </div>
</div>
