import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RequestService } from '../../services/request/request.service';

declare var $: any;
@Component({
  selector: 'app-addon-input',
  templateUrl: './addon-input.component.html',
  styleUrls: ['./addon-input.component.css'],
})
export class AddonInputComponent implements OnInit {
  checked = false;
  error = '';
  success = '';

  product_name = '';
  price: any;
  delivery_fees: any;
  qty: any;
  size = '';
  sizes = ['Small', 'Medium', 'Large'];

  care_level = '';
  care_levels = ['Low', 'Medium', 'High'];

  temperament = '';
  Temperaments = ['Aggressive', 'Semi Aggressive', 'Peaceful'];

  water_condition = '';
  water_conditions = ['Low PH', 'High PH'];

  more_description = '';

  inputForm: FormGroup;
  isSubmitted = false;

  countries = [];
  countryID = 0;

  vendors = [];
  vendor_id = 0;

  categories = [];
  subcategories = [];
  category_id = 0;
  subcategory_id = 0;
  country_err_msg = '';
  vendor_err_msg = '';
  category_err_msg = '';
  subcategory_err_msg = '';
  size_err_msg = '';
  carelvl_err_msg = '';
  temperament_err_msg = '';
  waterconditon_err_msg = '';
  button_disabled = false;
  token = '';
  id = 0;
  image: any;
  image_ext = '';
  image_err_msg = '';
  image_submit = false;
  product_image: File = null;
  product_image_url: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private auth: RequestService
  ) {
    this.id = parseInt(this.route.snapshot.paramMap.get('id'), 10);
    // this.vendorid = this.route.snapshot.paramMap.get('id');
    // this.base_url = environment.backendURL;
  }

  ngOnInit(): void {
    // this.success = 'k success';
    this.getCountryList();
    this.getMainCategories();

    this.getVendors();
    // this.tnc_accept =true;
    this.inputForm = this.formBuilder.group({
      product_name: ['', Validators.required],
      price: ['', [Validators.required, Validators.pattern('^[0-9.]*$')]],
      delivery_fees: [
        '',
        [Validators.required, Validators.pattern('^[0-9.]*$')],
      ],
      qty: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
      more_description: ['', Validators.required],
    });
    if (this.id != 0) {
      this.auth
        .getService('/api/admin/addon_row?t=product&id=' + this.id, this.token)
        .subscribe((res: any) => {
          if (res.status == '1') {
            // console.log('kk kk');
            console.log(res);
            this.product_name = res.data.product_name.trim();
            this.price = res.data.price;
            this.qty = res.data.qty; // parseInt(res.data.qty.trim());
            this.vendor_id = res.data.vendor_id; // parseInt(res.data.vendor_id.trim());
            this.category_id = res.data.category_id; //parseInt(res.data.category_id.trim());
            this.getSubCategories();
            // this.size  = res.data.size.trim();
            // this.care_level  = res.data.care_level.trim();
            // this.temperament  = res.data.temperament.trim();
            // this.water_condition  = res.data.water_condition.trim();
            this.more_description = res.data.more_description.trim();
            this.countryID = res.data.country_id; // parseInt(res.data.country_id.trim());

            if (
              res.data.product_image.trim() != '' &&
              res.data.product_image.trim() != null
            ) {
              this.image_submit == true;
              this.product_image_url = res.data.product_image;
              this.image_err_msg = '';
            }

            if (res.data.delivery_fees != '' && res.data.delivery_fees != null)
              this.delivery_fees = res.data.delivery_fees;

            if (
              res.data.subcategory_id != '' &&
              res.data.subcategory_id != null
            )
              this.subcategory_id = res.data.subcategory_id;
          } else {
            // localStorage.clear();
            this.router.navigate(['/addons']);
          }
        });
    }
    // ------------------
  }

  get inputFrm() {
    return this.inputForm.controls;
  }

  input() {
    this.isSubmitted = true;
    if (this.inputForm.invalid) {
      return;
    }

    if (this.vendor_id == 0) {
      this.vendor_err_msg = 'Please select';
      return;
    } else this.vendor_err_msg = '';

    if (this.category_id == 0) {
      this.category_err_msg = 'Please select';
      return;
    } else this.category_err_msg = '';

    if (this.subcategory_id == 0) {
      this.subcategory_err_msg = 'Please select';
      return;
    } else this.subcategory_err_msg = '';

    if (this.countryID == 0) {
      this.country_err_msg = 'Please select';
      return;
    } else this.country_err_msg = '';

    if (this.product_image_url == '' || this.image_submit == false) {
      this.image_err_msg = 'Please choose a image';
      return;
    } else this.image_err_msg = '';

    this.button_disabled = true;

    const data = {
      product_name: this.product_name,
      price: this.price,
      qty: this.qty,
      size: this.size,
      care_level: this.care_level,
      temperament: this.temperament,
      water_condition: this.water_condition,
      more_description: this.more_description,
      category_id: this.category_id,
      subcategory_id: this.subcategory_id,
      vendor_id: this.vendor_id,
      country_id: this.countryID,
      image: this.image,
      image_ext: this.image_ext,
      delivery_fees: this.delivery_fees,
      product_id: this.id,
    };

    //  this.fileToUpload = files.item(0);
    //  data.append('profile_pic', this.fileToUpload);

    console.log(data, 'data');
    //  console.log(environment.backendURL+'/api/signup', 'environment');
    this.auth
      .postAuthService('/api/admin/addon_input', data, this.token)
      .subscribe((res: any) => {
        if (res.status == '1' || res.status == true) {
          this.router.navigate(['/addons'], {
            state: { success_msg: 'Record saved successfully.' },
          });
        } else {
          this.error = res.message;
          this.button_disabled = false;
        }
      });
  }
  // ---------------------

  slectCountry(e) {
    this.countryID = e;
    this.country_err_msg = '';
  }

  slectVendor(e) {
    this.vendor_id = e;
    this.vendor_err_msg = '';
  }

  slectCategory(e) {
    this.category_id = e;
    this.category_err_msg = '';
    this.subcategory_id = 0;
    this.getSubCategories();
  }

  slectSubCategory(e) {
    this.subcategory_id = e;
    this.subcategory_err_msg = '';
  }

  slectSize(e) {
    this.size = e;
    this.size_err_msg = '';
  }

  slectCareLevel(e) {
    this.care_level = e;
    this.carelvl_err_msg = '';
  }

  slectTemperament(e) {
    this.temperament = e;
    this.temperament_err_msg = '';
  }

  slectWaterCondition(e) {
    this.water_condition = e;
    this.waterconditon_err_msg = '';
  }

  getCountryList() {
    this.token = localStorage.getItem('aqua_auth_token');
    this.auth.getService('/api/countries', this.token).subscribe((res: any) => {
      if (res.result_code == '1') {
        this.countries = res.data;
        // console.log(this.countries);
      }
    });
  }

  getVendors() {
    this.token = localStorage.getItem('aqua_auth_token');
    this.auth
      .getService('/api/admin/get_active_vendor_list?t=aqua_users', this.token)
      .subscribe((res: any) => {
        if (res.result_code == '1') {
          this.vendors = res.data;
          // console.log(this.vendors);
        }
      });
  }

  getMainCategories() {
    this.token = localStorage.getItem('aqua_auth_token');
    this.auth
      .getService('/api/admin/get_parent_categories', this.token)
      .subscribe((res: any) => {
        if (res.result_code == '1') {
          this.categories = res.data;
          // console.log(this.categories);
        }
      });
  }

  getSubCategories() {
    this.token = localStorage.getItem('aqua_auth_token');
    this.auth
      .getService(
        '/api/admin/get_sub_categories?pid=' + this.category_id,
        this.token
      )
      .subscribe((res: any) => {
        if (res.result_code == '1') {
          this.subcategories = res.data;
          // console.log(this.categories);
        }
      });
  }

  onFileInput(event, files: FileList) {
    const reader = new FileReader();
    const ext = event.target.files[0].name.split('.')[1];
    this.image_ext = ext;
    if (
      ext === 'png' ||
      ext === 'PNG' ||
      ext === 'jpg' ||
      ext === 'JPG' ||
      ext === 'jpeg' ||
      ext === 'JPEG'
    ) {
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        this.image = reader.result;
        this.product_image_url = reader.result;
        this.image_submit = true;
        this.image_err_msg = '';
      };
    } else {
      this.image_err_msg = 'File format is not valid.';
    }
  }
}
