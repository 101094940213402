import { Injectable } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AppStateService } from '..';
@Injectable({
  providedIn: 'root',
})
export class LayoutChangeObserverService {
  constructor(
    private appStateService: AppStateService,
    private breakpointObserver: BreakpointObserver
  ) {}

  init() {
    this.breakpointObserver
      .observe([Breakpoints.HandsetLandscape, Breakpoints.HandsetPortrait])
      .subscribe((result) => {
        if (result.matches) {
          this.appStateService.windowSize$.next({
            innerHeight: window.innerHeight,
            innerWidth: window.innerWidth,
          });

          console.log(this.appStateService.windowSize$.value);

          this.appStateService.deviceType$.next(
            window.innerWidth < 768 ? 'mobile' : 'desktop'
          );
        }
      });
  }
}
