import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';

import { RequestService } from '../../services/request/request.service';

declare var $: any;
@Component({
  selector: 'app-vendor-detail',
  templateUrl: './category-input.component.html',
  styleUrls: ['./category-input.component.css'],
})
export class CategoryInputComponent implements OnInit {
  checked = false;
  error = '';
  success = '';
  error2 = '';
  success2 = '';
  errorUpload = '';
  successUpload = '';
  category_name = '';

  inputForm: FormGroup;
  isSubmitted = false;
  cateries = [];
  categoryID = 0;
  token = '';
  id = 0;
  button_disabled = false;

  category_img_url: any;
  image_err_msg = '';
  image: any;
  image_ext = '';
  image_submit = false;
  app_url = environment.backendURL;

  is_featured = 'No';
  is_featured_err_msg = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private auth: RequestService
  ) {
    this.id = parseInt(this.route.snapshot.paramMap.get('id'), 10);
    // this.vendorid = this.route.snapshot.paramMap.get('id');
    // this.base_url = environment.backendURL;
  }

  ngOnInit(): void {
    // this.success = 'k success';
    this.getCategories();
    // this.tnc_accept =true;
    this.inputForm = this.formBuilder.group({
      category_name: ['', Validators.required],
    });
    if (this.id != 0) {
      this.auth
        .getService('/api/admin/edit_category?id=' + this.id, this.token)
        .subscribe((res: any) => {
          if (res.status == '1') {
            // console.log('kk kk');
            // console.log(res);
            this.category_name = res.data.category_name.trim();
            this.categoryID = res.data.parent_id; // parseInt(res.data.parent_id.trim());

            if (
              res.data.category_img != null &&
              res.data.category_img.trim() != '' &&
              res.data.category_img.trim() != null
            ) {
              this.image_submit = true;
              this.category_img_url = res.data.category_img;
              this.image_err_msg = '';
            }

            if (
              res.data.is_featured != null &&
              res.data.is_featured.trim() != '' &&
              res.data.is_featured.trim() != null
            ) {
              this.is_featured = res.data.is_featured;
              this.is_featured_err_msg = '';
            }
          } else {
            localStorage.clear();
            this.router.navigate(['/categories']);
          }
        });
    }
    // ------------------
  }

  get inputFrm() {
    return this.inputForm.controls;
  }

  input() {
    this.isSubmitted = true;
    if (this.inputForm.invalid) {
      return;
    }

    if (this.is_featured == '') {
      this.is_featured_err_msg = 'Please select';
      return;
    } else this.is_featured_err_msg = '';

    if (
      (this.category_img_url == '' || this.image_submit == false) &&
      this.is_featured == 'Yes'
    ) {
      this.image_err_msg = 'Please choose a image';
      // console.log(this.image_err_msg);

      // console.log(this.category_img_url);
      // console.log('img-submit'+this.image_submit);

      return;
    } else this.image_err_msg = '';

    this.button_disabled = true;

    const data = {
      category_name: this.category_name,
      parent_id: this.categoryID,
      image: this.image,
      image_ext: this.image_ext,
      is_featured: this.is_featured,
      category_id: this.id,
    };
    console.log(data, 'data');
    //  console.log(environment.backendURL+'/api/signup', 'environment');
    this.auth
      .postAuthService('/api/admin/category_input', data, this.token)
      .subscribe((res: any) => {
        if (res.status == '1' || res.status == true) {
          this.router.navigate(['/categories'], {
            state: { success_msg: 'Record saved successfully.' },
          });
        } else {
          this.error = res.message;
          this.button_disabled = false;
        }
      });
  }
  // ---------------------

  slectOption(e) {
    this.categoryID = e;
  }

  slect_is_featured(e) {
    this.is_featured = e;
    this.is_featured_err_msg = '';
  }

  getCategories() {
    this.token = localStorage.getItem('aqua_auth_token');
    this.auth
      .getService('/api/admin/get_parent_categories', this.token)
      .subscribe((res: any) => {
        if (res.result_code == '1') {
          this.cateries = res.data;
          // console.log(this.countries);
        }
      });
  }

  onFileInput(event, files: FileList) {
    const reader = new FileReader();
    var len = event.target.files[0].name.split('.').length;
    const ext = event.target.files[0].name.split('.')[len - 1];
    this.image_ext = ext;
    if (
      ext === 'png' ||
      ext === 'PNG' ||
      ext === 'jpg' ||
      ext === 'JPG' ||
      ext === 'jpeg' ||
      ext === 'JPEG'
    ) {
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        this.image = reader.result;
        this.category_img_url = reader.result;
        this.image_submit = true;
        this.image_err_msg = '';
      };
    } else {
      this.image_err_msg = 'File format is not valid.';
    }
  }
}
