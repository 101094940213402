import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { filter } from 'rxjs/operators';
import { notifyWhenKeyIsPresent } from '../../utils';

export type IRequestMethod = 'get' | 'post';
@Injectable({
  providedIn: 'root',
})
export class ApiResponseTrackerService {
  private readonly listenForResponseFromApis = {
    '/api/auction': { get: true },
  };
  responseTracker = {};
  constructor() {}

  saveResponse({
    url,
    data,
    method,
  }: {
    url: string;
    data: any;
    method: IRequestMethod;
  }) {
    let validUrlToTrack = false;
    let urlAsKey = '';
    const requestMethod = method.toLowerCase();
    for (const u of Object.keys(this.listenForResponseFromApis)) {
      if (url.includes(u)) {
        validUrlToTrack = true;
        urlAsKey = u;
        break;
      }
    }

    if (
      !(
        validUrlToTrack &&
        this.listenForResponseFromApis[urlAsKey][requestMethod] === true
      )
    ) {
      return;
    }
    if (!(urlAsKey in this.responseTracker)) {
      this.responseTracker[urlAsKey] = new BehaviorSubject(data);
    } else {
      this.responseTracker[urlAsKey].next(data);
    }
  }

  async listenForResponseFromURL({
    url,
    method,
  }: {
    url: string;
    method: IRequestMethod;
  }) {
    if (
      !Object.keys(this.listenForResponseFromApis).includes(url) &&
      this.listenForResponseFromApis[url][method] === true
    ) {
      return;
    }
    const found = await notifyWhenKeyIsPresent({
      key: url,
      object: this.responseTracker,
    });
    if (!found) {
      return;
    }
    return this.responseTracker[url].pipe(filter((f) => !!f));
  }
}
