<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
    #drawer
    class="sidenav"
    fixedInViewport
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="(isHandset$ | async) === false && !!token"
  >
    <mat-toolbar>Menu</mat-toolbar>
    <mat-nav-list *ngIf="!!token">
      <a mat-list-item *ngFor="let menuItem of menuItems" 
        >
        <a class="nav-link" routerLinkActive="active" [routerLink]="[menuItem.path]">
        <i class="material-icons mr-2">{{ menuItem.icon }}</i
        >{{ menuItem.title }}</a
      ></a>
      <a mat-list-item><a (click)="logout()"><i class="material-icons mr-2">settings_power</i>Logout</a></a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary">
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isHandset$ | async"
      >
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <span>Fishyhub {{ access | titlecase }} Portal</span>
    </mat-toolbar>
    <ng-content></ng-content>
    <!-- Add Content Here -->
  </mat-sidenav-content>
</mat-sidenav-container>
