import { Injectable } from '@angular/core';
import { differenceInDays } from 'date-fns';
@Injectable({ providedIn: 'root' })
export class ProductService {
  constructor() {}

  resetProductExpiry({
    expiresAt,
    expiryUpdatedAt,
  }: {
    expiresAt: string;
    expiryUpdatedAt: string;
  }) {
    const days = differenceInDays(
      new Date(expiresAt),
      new Date(expiryUpdatedAt)
    );
    return this.addDays(days);
  }

  addDays(days: number) {
    const date = new Date();
    date.setDate(date.getDate() + days);
    return new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      23,
      59,
      59
    );
  }
}
