import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { clear } from 'console';
import { RequestService } from '../services/request/request.service';

interface ISearchProps {
  searchTerm: string;
}

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css'],
})
export class UserListComponent implements OnInit {
  list: any;
  success_msg: string;
  error_msg: string;
  pageSize = 40;
  currentPageIndex = 0;
  totalRecords: number;
  // Variables req for search mode
  mode = true; // It toggles mode
  // filterRef holds the text user input to search
  @ViewChild('filter', { static: false }) filterRef: ElementRef;
  searchText: string;
  userList: Array<any> = []; // It will contain entries that match search
  sortBy = 1;
  editRecords: { label: string; userId: number; newValue: string } = {
    label: '',
    userId: null,
    newValue: '',
  };

  searchProps: ISearchProps = {
    searchTerm: '',
  };
  constructor(private auth: RequestService) {}

  ngOnInit(): void {
    this.getUserList();
    // this.resultList = this.list;
  }

  modeToggle() {
    this.mode = !this.mode;
  }

  saveRemark(userId: string, remark: string, follow_up: string) {
    if (!userId) {
      return;
    }
    const token = localStorage.getItem('aqua_auth_token');
    this.auth
      .postAuthService(
        '/api/admin/save_user_remark',
        {
          userId,
          remark,
          follow_up,
        },
        token
      )
      .subscribe((res: any) => {
        if (res.result_code === '1') {
          this.success_msg = 'Success! Remark saved for user';
          setTimeout(() => {
            this.success_msg = null;
          }, 3000);
        } else {
          this.error_msg = 'Try Again! Oops, remark could not be saved';
          setTimeout(() => {
            this.error_msg = null;
          }, 3000);
        }
      });
  }

  // It is for pagination
  onPageChange(change: PageEvent) {
    this.currentPageIndex = change.pageIndex;
    if (this.mode) {
      this.getUserList();
    } else {
      this.getUserList();
    }
  }

  manipulateQuizRes = (quizResponse) => {
    let res = {};

    try {
      for (const [key, value] of Object.entries(
        JSON.parse(quizResponse || '{}')
      )) {
        res[key] = value;
      }
    } catch {
      res = {};
    }

    return res;
  };

  // To get all users for searching sorting purposes
  getUserList() {
    const token = localStorage.getItem('aqua_auth_token');

    this.auth
      .postAuthService(
        '/api/admin/user_list/search',
        {
          sortBy: this.sortBy,
          start: this.currentPageIndex * this.pageSize,
          end: (this.currentPageIndex + 1) * this.pageSize,
        },
        token
      )
      .subscribe((res: any) => {
        if (res.result_code === 1) {
          this.totalRecords = res.data.totalCount;
          this.userList = (res.data?.list || []).map((u) => ({
            ...u,
            quizResponse: this.manipulateQuizRes(u.quiz_result),
          }));
        }
      });
  }

  // This funtion set sorting order
  onSort(request: number) {
    /*
    1: registered_at desc
    2: last_access
    3: no of items
    */
    this.sortBy = request;
    this.getUserList();
  }

  /**
   * This function saves user input in
   * fish type field of quiz section
   * @param user_id, event.target.value
   */
  saveFishType(id: number, updated: string) {
    const token = localStorage.getItem('aqua_auth_token');
    this.auth
      .postAuthService(
        '/api/admin/user_list/update_quiz/fishType',
        {
          user_id: id,
          fishType: updated,
        },
        token
      )
      .subscribe((res: any) => {
        if (res.result_code === 1) {
          console.log('Success');
        }
      });
  }

  /**
   * this function saves user input in
   * tank Capacity field of quiz section
   * @param user_id, event.target.value
   */
  saveTankCapacity(id: number, updated: string) {
    const token = localStorage.getItem('aqua_auth_token');
    this.auth
      .postAuthService(
        '/api/admin/user_list/update_quiz/tankCapacity',
        {
          user_id: id,
          tankCapacity: updated,
        },
        token
      )
      .subscribe((res: any) => {
        if (res.result_code === 1) {
          console.log('Success');
        }
      });
  }

  // When admin click on a field we save where he clicked i.e. on which field of which user
  // and show a input field there. Didn't added a permanent action button to save space
  editInit(label: string, userId: number) {
    this.editRecords = {
      label: label,
      userId: userId,
      newValue: '',
    };
  }

  // When admin writes something in the input box we save that value after checking the
  // user id and the id that we saved previously during editInit
  editSave(userId: number, newValue: string) {
    if (userId !== this.editRecords.userId) {
      this.errorMessage('Could not update record!');
      return 0;
    }
    this.editRecords.newValue = newValue;
  }

  // While submit we again check user id and label to be sure we are editing correct
  // value
  editSubmit(label: string, userId: number) {
    if (
      userId !== this.editRecords.userId ||
      label !== this.editRecords.label
    ) {
      this.errorMessage('Could not update record');
      return 0;
    }
    // If admin didn't write anything no need send API request
    if (this.editRecords.newValue === '') {
      return 0;
    }

    // If label is email we check if its of correct format,
    if (label === 'email') {
      // the below lines check if the string is valid email
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      re.test(this.editRecords.newValue.toLowerCase())
        ? this.updateUser(this.editRecords)
        : this.errorMessage('Invalid');
    } else {
      this.updateUser(this.editRecords);
    }
  }

  // made a seperate function to update so can use it for editing other values
  // in admin portal in future
  updateUser(newUser) {
    const token = localStorage.getItem('aqua_auth_token');
    this.auth
      .postAuthService('/api/admin/updateUser', newUser, token)
      .subscribe((res: any) => {
        if (res.result_code === 1) {
          // In other list where user searches and sorts data, after editing
          // admin do not need to refresh the page to see updated value
          this.userList.forEach((value) => {
            if (value.user_id === res.data.values.id) {
              value[`${res.data.label}`] = res.data.values.val;
            }
          });

          // In the first list admin again do not need to refresh page to see updated value
          this.list.forEach((value) => {
            if (value.user_id === res.data.values.id) {
              value[`'${res.data.label}'`] = res.data.values.val;
            }
          });
          this.clearEdit(); // remove edit input when value updated
        } else {
          this.errorMessage(res.message);
        }
      });
  }

  clearEdit() {
    this.editInit('', null);
  }

  errorMessage(error: string) {
    console.log(error);
    this.error_msg = error;
    setTimeout(() => {
      this.error_msg = '';
    }, 3000);
  }

  searchUser() {
    if (!this.searchProps.searchTerm) {
      return;
    }

    this.findUsersInDB({ searchTerm: this.searchProps.searchTerm });
  }
  findUsersInDB({ searchTerm }: { searchTerm: string }) {
    if (!searchTerm) {
      return;
    }
    this.auth
      .postService('/api/find-users-matching-search', {
        searchTerm,
        start: this.currentPageIndex * this.pageSize,
        end: (this.currentPageIndex + 1) * this.pageSize,
      })
      .subscribe((res: any) => {
        if (res.result_code === 1) {
          this.totalRecords = res.data.totalCount;
          this.userList = (res.data?.list || []).map((u) => ({
            ...u,
            quizResponse: this.manipulateQuizRes(u.quiz_result),
          }));
        }
      });
  }
}
