import { Injectable } from '@angular/core';
import { NavigationEnd, Params, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AppStateService } from '..';

@Injectable({ providedIn: 'root' })
export class RoutingStateService {
  constructor(
    private router: Router,
    private appStateService: AppStateService
  ) {}

  public init(): void {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(async (event: any) => {
        this.appStateService.routeNavigationEnd$.next(event);
      });
  }

  async getParams({ locationSearchProp }: { locationSearchProp: string }) {
    const params = {} as Params;
    const query = locationSearchProp.substring(1);
    const queryArray = query.split('&');
    for (const q of queryArray) {
      const pair = q.split('=');
      params[pair[0]] = decodeURIComponent(pair[1]);
    }
    return params;
  }
}
