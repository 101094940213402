import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { IApiResponse } from "../models";
import { RequestService } from "../services/request/request.service";

@Component({
  selector: "app-user-details",
  templateUrl: "./user-details.component.html",
  styleUrls: ["./user-details.component.scss"],
})
export class UserDetailsComponent implements OnInit {
  id: number;
  success_msg = "";
  name = "User";
  email = "";
  phone_no: number;
  address = "";
  postalCode: number;
  access: string;
  created_on = "";
  updated_on = "";
  lastAccess = "";
  quizResult = {
    experienceLevel: "",
    aquariumBestDescription: "",
    fishType: "",
    tankCapacity: "",
  };
  token: string;
  productList: ItemsEntityOrListOfItemsEntity[];
  priceInfo: PriceInfo;
  totalItems: number;
  offerCodeDetails: any;

  constructor(private route: ActivatedRoute, private auth: RequestService) {}

  ngOnInit(): void {
    this.id = parseInt(this.route.snapshot.paramMap.get("id"), 10);
    this.token = localStorage.getItem("aqua_auth_token");
    this.fetchUser();
    this.fetchCart();
  }
  fetchUser() {
    this.auth
      .getService("/api/admin/user_details?id=" + this.id, this.token)
      .subscribe((res: IApiResponse) => {
        if (res.result_code == 1) {
          this.name = res.data[0].name;
          this.email = res.data[0].email;
          this.phone_no = res.data[0].phone_num;
          this.address = res.data[0].address;
          this.postalCode = res.data[0].postal_code;
          this.created_on = res.data[0].created_on;
          this.updated_on = res.data[0].updated_on;
          if (res.data[0].quiz_result) {
            this.quizResult = res.data[0].quiz_result;
          }
          this.offerCodeDetails = res.data.order[0]?.offerCodeDetails;
        }
      });
  }

  fetchCart() {
    this.auth
      .getService("/api/admin/get-user-cart?id=" + this.id, this.token)
      .subscribe((res: userCart) => {
        if (res.result_code == 1) {
          this.priceInfo = res.data.priceInfo;
          this.totalItems = res.data.totalItems;
          this.productList = res.data.listOfItems;
        }
      });
  }
}

export interface quizResult {
  experienceLevel: string;
  aquariumBestDescription: string;
  fishType: string;
  tankCapacity: string;
}
export interface userCart {
  status: boolean;
  result_code: number;
  message: string;
  data: Data;
}
export interface Data {
  shoppingCart: ShoppingCart;
  priceInfo: PriceInfo;
  totalItems: number;
  listOfItems?: ItemsEntityOrListOfItemsEntity[] | null;
}
export interface ShoppingCart {
  8: 8;
}
export interface ItemsEntityOrListOfItemsEntity {
  id: number;
  cart_id: number;
  product_id: number;
  price: number;
  quantity: number;
  option_id: number;
  option_price: number;
  delivery_type: string;
  delivery_price: number;
  vendor_id: number;
  qty: number;
  product_image: string;
  product_name: string;
  addon_name: string;
  price_symbol: string;
  vendor_name: string;
  subtotal: number;
}
export interface PriceInfo {
  total: number;
  subtotal: number;
  shipping: number;
  discount: number;
  offerCode?: null;
}
