import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { async } from 'rxjs/internal/scheduler/async';
import { environment } from '../../../environments/environment';

import { RequestService } from '../../services/request/request.service';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.css'],
})
export class OrderDetailsComponent implements OnInit {
  list: any;
  token = '';
  cname = '';
  checked = false;
  success_msg = '';
  error_msg = '';
  customer_name = '';
  shipping_address = '';
  billing_address = '';
  shipping_email = '';
  billing_email = '';
  sub_total = 0;
  shipping_total = 0;
  total = 0;
  created_on = '';
  currency = '';
  whole_order_status = '';
  payment_status = '';
  app_url = environment.backendURL;
  id: any;
  updated_on = '';
  button_disabled = false;
  cancel_popup = '';
  cancellation_error_msg = '';
  cancellation_rason = '';
  access = '';
  user_id = '';
  option_price = 0;
  deliveryDiscountAmount: any;
  offerCodeDetails: any;
  deliveryZoneDiscount: number;
  adjustedShippingAmount: number;
  orderRemarks: string;
  remarks: string;
  constructor(
    private router: Router,
    private requestService: RequestService,
    private route: ActivatedRoute
  ) {
    this.id = this.route.snapshot.paramMap.get('id');
    this.cname = this.route.snapshot.queryParamMap.get('cname');
    const navigation = this.router.getCurrentNavigation();
    const state = navigation.extras.state as {
      success_msg: string;
      error_msg: string;
    };
    if (state) {
      if (state.success_msg) {
        this.success_msg = state.success_msg;
        setTimeout(() => {
          this.success_msg = '';
        }, 3000);
      } else if (state.error_msg) {
        this.error_msg = state.error_msg;
        setTimeout(() => {
          this.error_msg = '';
        }, 3000);
      }
    }
  }

  ngOnInit(): void {
    this.fetchOrder();
  }

  fetchOrder() {
    this.token = localStorage.getItem('aqua_auth_token');
    this.access = localStorage.getItem('aqua_access');
    this.user_id = localStorage.getItem('aqua_userid');
    this.requestService
      .getService('/api/admin/order_details?id=' + this.id, this.token)
      .subscribe((res: any) => {
        if (res.result_code === 1) {
          this.list = res.data.details;
          this.customer_name = res.data.order[0].customer_name;
          this.shipping_address = res.data.order[0].shipping_address;
          this.billing_address = res.data.order[0].billing_address;
          this.shipping_email = res.data.order[0].shipping_email;
          this.billing_email = res.data.order[0].billing_email;
          this.sub_total = res.data.order[0].sub_total;
          this.shipping_total = res.data.order[0].shipping_total;
          this.total = res.data.order[0].total;
          this.created_on = res.data.order[0].created_on;
          this.updated_on = res.data.order[0].updated_on;
          this.currency = res.data.order[0].currency;
          this.whole_order_status = res.data.order[0].whole_order_status;
          this.payment_status = res.data.order[0].payment_status;
          this.cancellation_rason = res.data.order[0].cancel_reason;
          this.option_price = res.data.order[0].option_price;
          this.deliveryDiscountAmount =
            res.data.order[0]?.delivery_discount_amount;
          this.offerCodeDetails = res.data.order[0]?.offerCodeDetails;
          this.deliveryZoneDiscount = +res.data.order[0].delivery_zone_discount
            ? +res.data.order[0].delivery_zone_discount
            : 0;
          this.adjustedShippingAmount =
            +res.data.order[0].adjusted_shipping_fee;
          this.remarks = res.data.order[0].remarks;
          this.orderRemarks = res.data.order[0].order_remarks;
        }
      });
  }

  fetchDeliveryRemarks = async () => {
    this.token = localStorage.getItem('aqua_auth_token');
    this.requestService.getService(
      '/api/admin/delivery-remarks?orderId=' + this.id,
      this.token
    );
  };

  order_status(status) {
    if (status == 'cancelled' && this.cancellation_rason == '') {
      this.cancellation_error_msg = 'Cancellation reason is required';
      return;
    } else {
      this.cancellation_error_msg = '';
    }
    this.cancel_popup = '';
    this.button_disabled = true;
    const data = {
      status: status,
      reason: this.cancellation_rason,
      id: this.id,
    };
    this.requestService
      .postAuthService('/api/admin/order_status', data, this.token)
      .subscribe((res: any) => {
        if (res.status == '1' || res.status == true) {
          this.whole_order_status = res.data.whole_order_status;
          this.button_disabled = false;
        } else {
          this.error_msg = res.message;
          this.button_disabled = false;
        }
      });
  }

  cancelShowPopup() {
    this.cancel_popup = 'show';
    this.cancellation_error_msg = '';
  }

  cancelClosePopup() {
    this.cancel_popup = '';
    this.cancellation_error_msg = '';
  }
}
