<div class="main-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
          <div class="alert alert-green" *ngIf="success_msg!=''">
              <span>{{success_msg}}</span>
         </div>
        <div class="card">
          <div class="card-header card-header-danger">
            <h4 class="card-title ">Vendor List</h4>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table">
                <thead class=" text-primary">
                  <tr>
                    <th> ID </th>
                    <th> Name </th>
                    <th> Email </th>
                    <th> Mobile </th>
                    <th> Address </th>
                    <th>Zones</th>
                    <th> Star Seller </th>

                    <th> Action</th>
                    <th> Status </th> 
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of list">
                    <td> {{ row.id }} </td>
                    <td class="text-capitalize"> {{ row.first_name }} {{ row.last_name }} </td>
                    <td> {{ row.email }} </td>
                    <td> {{ row.phone_num }} </td>
                    <td> {{ row.address_line1 }}  {{ row.city }}  {{ row.state }} </td>
                    <td>  {{row.zone}}  </td>
                    <td> {{ row.is_star_seller }} </td>

                    <td>
                      <a [routerLink]="['/vendor_input/' + row.id]">
                        <span _ngcontent-gxv-c19="" class="material-icons icon-image-preview">visibility</span>
                      </a>&nbsp;
                      <a [routerLink]="['/vendor_input/' + row.id]">
                        <span _ngcontent-gxv-c19="" class="material-icons icon-image-preview">delete</span>
                      </a>
                    </td>
                    <td style="text-transform: capitalize; color: blue;" *ngIf="row.status.trim() == 'active'"> {{ row.status }} </td>
                    <td style="text-transform: capitalize; color: red;" *ngIf="row.status.trim() != 'active'"> {{ row.status }} </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
