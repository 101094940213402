import { Component, OnInit } from '@angular/core';
import { IApiResponse, IVendors } from '../models';
import { RequestService } from '../services/request/request.service';
import { NotificationService } from '../services/notification/notification.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-merchant-orders',
  templateUrl: './merchant-orders.component.html',
  styleUrls: ['./merchant-orders.component.scss'],
})
export class MerchantOrdersComponent implements OnInit {
  access = localStorage.getItem('aqua_access');
  token: string;
  list = [];
  total = 0;
  pageIndex = 0;
  size = 0;
  url: string;
  download;
  remarks = {
    orderId: '',
    vendorId: '',
    text: '',
  };
  vendors: IVendors[] = [];
  vendorId: number = 0;
  range = new FormGroup({
    start: new FormControl('', Validators.required),
    end: new FormControl('', Validators.required),
  });
  date = {
    start: null,
    end: null,
  };
  constructor(
    private auth: RequestService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.token = localStorage.getItem('aqua_auth_token');
    this.access = localStorage.getItem('aqua_access');
    this.fetchOrders();
    this.getvendors();
  }

  fetchOrders() {
    const data = {
      pageIndex: this.pageIndex,
      pageSize: 40,
      start: '',
      end: '',
      vendorId: this.vendorId,
    };
    this.auth
      .postAuthService('/api/admin/merchantOrders', data, this.token)
      .subscribe((res: IApiResponse) => {
        if (res.result_code === 1) {
          this.list = res.data.list;
          this.total = res.data.count;
          this.size = res.data.size;
        }
      });
  }

  fixDecimal = (unformatMerchantOrderList) => {
    const formatMerchantOrderList = unformatMerchantOrderList.map((val) => {
      return {
        ...val,
        plantsAndLivestock: val.plantsAndLivestock
          ? val.plantsAndLivestock.toFixed(2)
          : 0.0,
        others: val.others ? val.others.toFixed(2) : 0.0,
        total: val.total ? val.total.toFixed(2) : 0.0,
        ordered_on: new Date(val.ordered_on),
      };
    });
    return formatMerchantOrderList;
  };

  checkDateRange = () => {
    if (this.range.controls.end.errors || this.range.controls.end.pristine) {
      return false;
    }
    if (this.range.controls.start.errors || this.range.controls.end.pristine) {
      return false;
    }
    const a = new Date(this.range.controls.start.value);
    const b = new Date(this.range.controls.end.value);
    if (a > b) {
      return false;
    }
    return true;
  };

  formatToString = (list) => {
    const heading =
      'order_id,customer,vendor_id,merchant,ordered_on,status,order_status_remarks,remarks,delivery_remarks,total,shipping_total,plantsAndLivestock,others \n';
    const csvData = list.map((val) => {
      let data = '';
      data = `${val.order_id},${val.customer},${val.vendor_id},${
        val.merchant
      },${val.ordered_on},${val.status},${
        val.order_remarks?.replace(/,/g, '&') ?? ''
      },${val.remarks?.replace(/,/g, '&') ?? ''},${
        val.delivery_remarks?.replace(/,/g, '&') ?? ''
      },${val.total},${val.shipping_total},${val.plantsAndLivestock},${
        val.others
      }\n`;
      return data;
    });
    csvData.unshift(heading);
    return csvData;
  };

  clearDatePicker() {
    this.range.reset();
    this.date = {
      start: '',
      end: '',
    };
  }

  downloadCSV = () => {
    // check if user set start and end date
    if (!this.checkDateRange()) {
      this.errorMessage('Given time range is invalid');
      return 0;
    }
    // fetch data
    try {
      this.date = JSON.parse(JSON.stringify(this.range.value));
      const data = {
        pageIndex: '',
        pageSize: '',
        start: this.date.start,
        end: this.date.end,
        vendorId: this.vendorId,
      };
      this.auth
        .postAuthService('/api/admin/merchantOrders', data, this.token)
        .subscribe((res: IApiResponse) => {
          if (res.result_code === 1) {
            const fetchMerchantOrders = res.data.list;
            if (!fetchMerchantOrders) {
              throw new Error('Couldnt fetch orders');
            }
            // format amount to get fixed value after 2 digits and format date
            const merchantOrders = this.fixDecimal(fetchMerchantOrders);
            if (!merchantOrders) {
              throw new Error('Couldnt fix Decimal 2 digits after');
            }
            // format the JSON to a string
            const merchantOrdersString = this.formatToString(merchantOrders);
            if (!merchantOrdersString) {
              throw new Error('Couldnt format to string');
            }
            // download it
            const data = new Blob(merchantOrdersString, { type: 'text/csv' });
            const csvURL = window.URL.createObjectURL(data);
            const tempLink = document.createElement('a');
            tempLink.href = csvURL;
            tempLink.setAttribute('download', 'merchantOrder.csv');
            tempLink.click();
          } else {
            return false;
          }
        });
      // return merchantOrders;
    } catch (error) {
      console.error(error);
    }
  };

  onPageChange(pageSettings: any) {
    this.pageIndex = pageSettings.pageIndex;
    this.fetchOrders();
  }

  // for saving delivery remark
  onChange(orderId: string, vendorId: string, remarks: string) {
    this.remarks.orderId = orderId;
    this.remarks.vendorId = vendorId;
    this.remarks.text = remarks;
  }

  // send saved delivery remark to backend
  submitRemark(orderId: string, vendorId: string) {
    if (
      this.remarks.orderId !== orderId ||
      this.remarks.vendorId !== vendorId
    ) {
      this.remarks = { orderId: '', vendorId: '', text: '' };
      this.errorMessage("Sorry,remarks could'nt be saved please try again!");
      this.fetchOrders();
      return 0;
    }
    this.successMessage('Please wait, saving...');
    const data = this.remarks;
    this.auth
      .postAuthService('/api/admin/update_delivery_remarks', data, this.token)
      .subscribe((res: IApiResponse) => {
        if (res.result_code === 1) {
          this.successMessage('Successfully updated remarks!!!');
          this.fetchOrders();
        } else {
          this.errorMessage(
            'Error occurred while updating remarks, Please try again!!!'
          );
        }
      });
    this.clearRemark();
  }

  getvendors() {
    this.token = localStorage.getItem('aqua_auth_token');
    this.auth
      .getService('/api/admin/get_vendors_with_orders', this.token)
      .subscribe((res: IApiResponse) => {
        if (res.result_code === 1) {
          this.vendors = res.data;
        }
      });
  }

  // clear control over a delivery remark input field
  clearRemark() {
    this.remarks.orderId = '';
    this.remarks.vendorId = '';
    this.remarks.text = '';
    this.fetchOrders();
  }

  successMessage(message: string) {
    this.notificationService.show({ message: message });
  }

  errorMessage(message: string) {
    this.notificationService.show({ message: message, type: 'danger' });
  }

  updateDeliveryStatus(orderId: string, vendorId: number, status: string) {
    const data = {
      orderId,
      vendorId,
      status,
    };
    this.auth
      .postAuthService('/api/admin/update_delivery_remarks', data, this.token)
      .subscribe((res: IApiResponse) => {
        if (res.result_code === 1) {
          this.successMessage('Successfully updated status');
          this.fetchOrders();
        } else {
          this.errorMessage('Error occurred while updating status');
        }
      });
  }

  onMerchantSelection = (merchantId: number) => {
    // console.log(merchantId);
    this.vendorId = merchantId;
    this.fetchOrders();
  };
}
