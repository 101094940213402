import { Component, OnInit, ChangeDetectorRef } from '@angular/core';

import { Router } from '@angular/router';
import { RequestService, ApiMessageService } from '../../services';
import { offerTableColumns } from './offer-table-columns.config';
import { LocalDataSource } from 'ng2-smart-table';
import { IApiResponse, IOfferList } from '../../models';
import { sleep } from '../../utils';

@Component({
  selector: 'app-offer-list-page',
  templateUrl: './offer-list-page.component.html',
  styleUrls: ['./offer-list-page.component.scss'],
})
export class OfferListPageComponent implements OnInit {
  readonly RECORDS_PER_PAGE = 20;

  settings: any;
  source: LocalDataSource = new LocalDataSource();

  constructor(
    private requestService: RequestService,
    private router: Router,
    private apiMessageService: ApiMessageService,

    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.getOfferList();
  }
  getOfferList() {
    this.requestService
      .getService(`/api/offer/get_offer_code_details`)
      .subscribe((apiRes: IApiResponse) => {
        this.changeSettingsForTable(apiRes.data);
      });
  }

  addRecord(event: any) {
    this.requestService
      .postService('/api/offer/add-new-offer', {
        ...event.newData,
      })
      .subscribe((apiRes: IApiResponse) => {
        this.apiMessageService.manageAlertMessage({
          apiRes,
        });
        if (apiRes.result_code === 1) {
          this.getOfferList();
          event.confirm.resolve(event.newData);
        }
      });
  }

  updateRecord(event: any) {
    this.requestService
      .postAuthService('/api/offer/update-offer-details', {
        newOfferDetails: event.newData,
      })
      .subscribe((apiRes: IApiResponse) => {
        this.apiMessageService.manageAlertMessage({
          apiRes,
          showMessageFor: 3000,
        });
        if (apiRes.result_code === 1) {
          this.getOfferList();
          event.confirm.resolve(event.newData);
        }
      });
  }

  async changeSettingsForTable(offerList: IOfferList[]) {
    const offerCodeTableColumn = {
      offerId: offerTableColumns.offerId,
      offerCode: offerTableColumns.offerCode,
      offerCodeType: offerTableColumns.offerCodeType,
      startDate: offerTableColumns.startDate,
      expiryDate: offerTableColumns.expiryDate,
      discountType: offerTableColumns.discountType,
      minCartSubtotal: offerTableColumns.minCartSubtotal,
      percentageAmount: offerTableColumns.percentageAmount,
      status: offerTableColumns.status,
      offerCodeDiscountOn: offerTableColumns.offerCodeDiscountOn,
    };

    const tempSettings = {
      actions: { add: true, edit: true, delete: false },
      add: {
        addButtonContent: '<span class="material-icons">add</span>',
        createButtonContent: '<span class="material-icons">done</span>',
        cancelButtonContent: '<span class="material-icons">close</span>',
        confirmCreate: true,
      },
      edit: {
        editButtonContent: '<span class="material-icons">edit</span>',
        saveButtonContent: '<span class="material-icons">done</span>',
        cancelButtonContent: '<span class="material-icons">close</span>',
        confirmSave: true,
      },
      columns: offerCodeTableColumn,
      pager: {
        display: true,
        perPage: this.RECORDS_PER_PAGE,
      },
    };
    await sleep(100);

    this.settings = Object.assign({}, tempSettings);
    this.source.load(offerList);
    this.cdr.detectChanges();
  }
}
