<div class="alert alert-green" *ngIf="success_msg">
  <span>{{ success_msg }}</span>
</div>
<div class="alert alert-danger" *ngIf="error_msg">
  <span>{{ error_msg }}</span>
</div>
<div fxLayout="column" class="card">
  <div
    class="card-header card-header-danger"
    fxLayout="column"
    fxLayoutGap="10px"
  >
    <div>
      <h4 class="card-title">Sort List</h4>
      <div class="sortclass">
        <button
          (click)="onSort(1)"
          [ngClass]="this.sortBy === 1 ? 'btn btn-info' : 'btn btn-dark'"
        >
          Registration Date (New)
        </button>
        <button
          (click)="onSort(2)"
          [ngClass]="this.sortBy === 2 ? 'btn btn-info' : 'btn btn-dark'"
        >
          Last Login
        </button>
        <button
          (click)="onSort(3)"
          [ngClass]="this.sortBy === 3 ? 'btn btn-info' : 'btn btn-dark'"
        >
          No of items
        </button>
      </div>
    </div>

    <div fxLayout="column" fxLayoutGap="10px">
      <div fxFxlayout="row" fxLayoutGap="10px">
        <div fxLayout="column">
          <label>
            Type user info (email, first name, last name, user id, user name)
          </label>
          <input
            type="search"
            placeholder="user info"
            ngModelOptions="{standalone: true}"
            [(ngModel)]="searchProps.searchTerm"
          />
        </div>
        <button class="btn btn-info btn-sm" (click)="searchUser()">
          Find user
        </button>
      </div>
    </div>
  </div>
  <div class="card-body table-responsive">
    <table class="table">
      <thead class="text-primary">
        <tr>
          <th>Action</th>

          <th>Name</th>

          <th>Email</th>

          <th>Phone</th>

          <th>Registration Date</th>

          <th>Last Login</th>

          <th>Experience</th>

          <th>Aquarium Description</th>

          <th>Fish Type</th>

          <th>Tank Capacity</th>

          <th>No of items</th>

          <th>Order Amount</th>

          <th>Follow Up</th>

          <th>Remarks</th>

          <th>Action(Remarks)</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of userList">
          <td>
            <a [routerLink]="['/admin/user-details/' + row.user_id]">
              <span
                _ngcontent-gxv-c19=""
                class="material-icons icon-image-preview"
                >visibility</span
              >
            </a>
          </td>

          <td>{{ row.full_name }}</td>

          <td
            class="text-capitalize"
            *ngIf="
              !(
                this.editRecords.label === 'email' &&
                this.editRecords.userId === row.user_id
              )
            "
            (click)="editInit('email', row.user_id)"
          >
            {{ row.email }}
          </td>

          <td
            class="text-capitalize"
            *ngIf="
              this.editRecords.label === 'email' &&
              this.editRecords.userId === row.user_id
            "
          >
            <input
              type="text"
              (change)="editSave(row.user_id, $event.target.value)"
              [value]="row.email"
            />
            &nbsp;
            <mat-icon class="pointer" (click)="editSubmit('email', row.user_id)"
              >check</mat-icon
            >
            &nbsp;
            <mat-icon class="pointer" (click)="clearEdit()">cancel</mat-icon>
          </td>

          <td
            class="pointer"
            *ngIf="
              !(
                this.editRecords.label === 'phone_num' &&
                this.editRecords.userId === row.user_id
              )
            "
            (click)="editInit('phone_num', row.user_id)"
          >
            {{ row.phone_num }}
          </td>

          <td
            *ngIf="
              this.editRecords.label === 'phone_num' &&
              this.editRecords.userId === row.user_id
            "
          >
            <input
              type="text"
              (change)="editSave(row.user_id, $event.target.value)"
              [value]="row.phone_num"
            />
            &nbsp;
            <mat-icon
              class="pointer"
              (click)="editSubmit('phone_num', row.user_id)"
              >check</mat-icon
            >
            &nbsp;
            <mat-icon class="pointer" (click)="clearEdit()">cancel</mat-icon>
          </td>

          <td>{{ row.created_on | date: 'dd/MM/yyyy, h:mm a' }}</td>

          <td>
            {{ row.last_access | date: 'dd/MM/yyyy, h:mm a' }}
          </td>

          <td>
            {{ row.quizResponse.experienceLevel || '' }}
          </td>

          <td>
            {{ row.quizResponse.aquariumBestDescription || '' }}
          </td>

          <td>
            {{ row.quizResponse.fishType || '' }}
            <textarea
              rows="2"
              cols="30"
              (change)="saveFishType(row.user_id, $event.target.value)"
              [value]="row.quizResponse.fishType || ''"
            ></textarea>
          </td>

          <td>
            {{ row.quizResponse.tankCapacity || '' }}
            <textarea
              rows="2"
              cols="30"
              (change)="saveFishType(row.user_id, $event.target.value)"
              [value]="row.quizResponse.tankCapacity || ''"
            ></textarea>
          </td>

          <td>
            {{ row.no_of_items }}
          </td>

          <td>{{ row.order_amount | number: '1.2-2' }}</td>

          <td>
            <textarea
              rows="2"
              cols="30"
              #followUp
              [value]="row.follow_up"
            ></textarea>
          </td>

          <td>
            <textarea
              rows="2"
              cols="30"
              #userRemark
              [value]="row.remark"
            ></textarea>
          </td>
          <td>
            <button
              mat-raised-button
              type="button"
              (click)="
                saveRemark(row.user_id, userRemark.value, followUp.value)
              "
              class="btn btn-success"
            >
              Save
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div>
    <mat-paginator
      [length]="totalRecords"
      [pageSize]="pageSize"
      (page)="onPageChange($event)"
    >
    </mat-paginator>
  </div>
</div>
