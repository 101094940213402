import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { RequestService } from '../../services/request/request.service';

@Component({
  selector: 'app-vendor-list',
  templateUrl: './vendor-list.component.html',
  styleUrls: ['./vendor-list.component.css'],
})
export class VendorListComponent implements OnInit {
  list: any;
  token = '';
  checked = false;
  success_msg = '';
  error_msg = '';
  zones: any;
  constructor(private router: Router, private auth: RequestService) {
    const navigation = this.router.getCurrentNavigation();
    const state = navigation.extras.state as {
      success_msg: string;
      error_msg: string;
    };
    if (state != undefined) {
      if (state.success_msg) {
        this.success_msg = state.success_msg;
        setTimeout(() => {
          this.success_msg = '';
        }, 3000);
      } else if (state.error_msg) {
        this.error_msg = state.error_msg;
        setTimeout(() => {
          this.error_msg = '';
        }, 3000);
      }
    }
  }

  ngOnInit(): void {
    this.token = localStorage.getItem('aqua_auth_token');
    this.auth
      .getService('/api/admin/get_list?t=aqua_users', this.token)
      .subscribe((res: any) => {
        if (res.result_code == '1') {
          let vendor = res.data;
          this.get_zones(vendor);
        }
      });
  }

  get_zones(vendor: any) {
    this.auth
      .getService('/api/admin/get_zones', this.token)
      .subscribe((res: any) => {
        if (res.result_code == '1') {
          this.zones = res.data;
          this.show_zones(vendor);
        }
      });
  }

  show_zones(vendor: any) {
    vendor.forEach((element) => {
      if (Object.keys(this.zones).includes(element.id.toString())) {
        element['zone'] = this.zones[element.id.toString()];
      } else {
        element['zone'] = ' ';
      }
    });
    this.list = vendor;
  }
}
