<div class="main-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
          <div class="alert alert-green" *ngIf="success_msg!=''">
              <span>{{success_msg}}</span>
         </div>
        <div class="card">
          <div class="card-header card-header-danger">
            <h4 class="card-title ">Addons List</h4>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table">
                <thead class=" text-primary">
                  <tr>
                    <th> ID </th>
                    <th> Name </th>
                    <th> Price($) </th>
                    <th> Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of list">
                    <td> {{ row.id }} </td>
                    <td> {{ row.addon_name }} </td>
                    <td> {{ row.addon_price }} </td>
                    <td>
                      <a (click)="router.navigateByUrl('/product_input/' + row.id)">
                        <span _ngcontent-gxv-c19="" class="material-icons icon-image-preview">visibility</span>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
